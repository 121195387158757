import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { useAppStateContext } from '../../../state/AppStateContext';
import { ConnectionMethod } from '../../../models/CloudWalletTypes';
import * as AcaPyTypes from '../../../models/AcaPyModels';
import { FlexColTight, Line, Span } from '../../common/CommonStyles';

interface Props {
  method: ConnectionMethod.SINGLE_USE_INVITATION|ConnectionMethod.MULTI_USE_INVITATION
}

interface ConnectionStatus {
  error: boolean,
  titleKey: string,
  messageKey: string
}

const ConnectionInvitation: React.FC<Props> = ({method}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [invitationResult, setInvitationResult] = useState<AcaPyTypes.InvitationResult | undefined>(undefined);
  const [showInvitationUrl, setShowInvitationUrl] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [alias, setAlias] = useState<string|undefined>(undefined);
  const [invitationRequestSent, setInvitationRequestSent] = useState<boolean>(method === ConnectionMethod.MULTI_USE_INVITATION);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    error: false,
    titleKey: "app.components.ConnectionInvitation.creatingInvitationTitle",
    messageKey: "app.components.ConnectionInvitation.creatingInvitationMsg"
  });

  useEffect(() => {
    // Automatically send request to create/fetch a multi-use invitation.
    // Single-use invitation request is not sent automatically, because
    // the user has the option to give an alias to the connection.
    if (!invitationResult && method === ConnectionMethod.MULTI_USE_INVITATION) {
      sendInvitationRequest(true);
    }
  }, []);

  const sendInvitationRequest = (multiUse: boolean) => {
    setInvitationRequestSent(() => true);
    appContext.createInvitationAsync(multiUse, alias)
    .then(invitation => {
      if (invitation) {
        setInvitationResult(() => invitation);
      }
      else {
        setConnectionStatus(() => ({
          error: true,
          titleKey: "app.components.ConnectionInvitation.invitationErrorTitle",
          messageKey: "app.components.ConnectionInvitation.invitationErrorMsg"
        }));
      }
    })
  }

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  }

  if (invitationResult) {
    return (
      <FlexColTight>
        <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 2}}>
          {t("app.components.ConnectionInvitation.scanInvitationHelpText")}
        </Typography>
        <Box sx={{m: "auto"}}>
          <QRCode size={250} value={invitationResult.invitation_url!} style={{marginRight: `${tokens.spacing.xs}`}}/>
        </Box>
        <Line>
          <Span>{t("common.actions.or.title")}</Span>
        </Line>
        <Typography variant="body1" gutterBottom sx={{m: "auto"}}>
          {t("app.components.ConnectionInvitation.copyInvitationHelpText")}
          <Button variant="text" onClick={() => setShowInvitationUrl(old => !old)}>
            { showInvitationUrl ?
              t("app.components.ConnectionInvitation.hideInvitationUrl") :
              t("app.components.ConnectionInvitation.showInvitationUrl")
            }
          </Button>
        </Typography>
        { showInvitationUrl && 
          <TextField multiline fullWidth variant={"filled"} value={invitationResult.invitation_url!} style={{backgroundColor: `${tokens.colors.whiteBase}`}} sx={{m: 0}}/>
        }
        <Button
          variant="contained"
          onClick={() => copyToClipboard(invitationResult.invitation_url!)}
          sx={{ mt: 1, mx: "auto" }}
        >{isCopied ? t("app.components.ConnectionInvitation.urlCopied") : t("app.components.ConnectionInvitation.copyUrl")}</Button>
      </FlexColTight>
    );
  }
  if (!invitationRequestSent) {
    return (
      <FlexColTight>
        <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 2}}>
          {t("app.components.ConnectionInvitation.aliasHelpText")}
        </Typography>
        <TextField
          name="alias"
          label={t("app.components.ConnectionInvitation.aliasTextField")}
          value={alias}
          onChange={(e) => setAlias(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() => sendInvitationRequest(false)}
          sx={{ mt: 1 }}
        >
          {t("app.components.ConnectionInvitation.sendInvitationReguest")}
        </Button>
      </FlexColTight>
    );
  }
  return connectionStatus.error ? (
    <Alert severity="error">
      <AlertTitle>{t(connectionStatus.titleKey)}</AlertTitle>
      {t(connectionStatus.messageKey)}
    </Alert>
  ) : (
    <Alert severity="info" icon={<CircularProgress />} >
      <AlertTitle>{t(connectionStatus.titleKey)}</AlertTitle>
      {t(connectionStatus.messageKey)}
    </Alert>
  );
}

export default ConnectionInvitation;