/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {useState} from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { suomifiDesignTokens as tokens, Button, Heading, Text } from 'suomifi-ui-components'
import { FlexCol, FlexRow } from '../common/CommonStyles'
import { TextField } from '@mui/material';
import LoadingModal from '../modals/LoadingModal'
import * as OmaYritysApi from "../../api/OmaYritysApi";
import { ButtonLink } from '../common/InputStyles'
import { useAppStateContext } from '../../state/AppStateContext';

const LoginView = () => {
  const appContext = useAppStateContext();
  const [businessId, setBusinessId] = useState('7023610-7'); // Acanne: 7025394-3 Hosharaff: 7011367-6
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|undefined>(undefined);

  const styles = css({
    padding: tokens.spacing.m,
    boxSizing: 'border-box',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1200px',
    '@media (min-width: 992px)': {
      padding: tokens.spacing.xxl
    }
  });

  const doLogin = (businessId: string) => {
    setError(undefined);
    setLoading(true);
    OmaYritysApi.Login(businessId)
    .then(res => {
      if (res.data) {
        appContext.setCompany(res.data);
      }
      setLoading(false);
      navigate("/");
    })
    .catch(err => {
      setError("app.login.companyNotFound");
      setLoading(false);
      console.log(err);
    })
  }

  return (
    <div css={styles}>
      <FlexRow>
        <FlexCol>
          <div>
            <Heading variant='h1hero'>
              {t("app.login.heading")}
            </Heading>
          </div>

          { error && 
            <Text variant="lead">
              {t(error)}
            </Text>
          }
          <div>
            <TextField type="text" label={t("app.login.companyLabel")} value={businessId} onChange={e => setBusinessId(e.target.value)} variant="outlined" />
          </div>
          <div style={{ margin: `${tokens.spacing.m} 0` }}>
            <Button icon={"login"} onClick={() => doLogin(businessId)}>
              {t("app.login.loginbutton")}
            </Button>
          </div>

          <div style={{color: tokens.colors.depthDark1}}>
            {t("app.login.useTestLogins")}
            <ul>
              <li>
                <ButtonLink onClick={() => doLogin("7017001-3")}>Raksa Oy</ButtonLink>
              </li>
              <li>
                <ButtonLink onClick={() => doLogin("7011367-6")}>Hosharaff 238 Testifirma</ButtonLink>
              </li>
              <li>
                <ButtonLink onClick={() => doLogin("7025394-3")}>Acanne Testifirma</ButtonLink>
              </li>
              <li>
                <ButtonLink onClick={() => doLogin("7023610-7")}>Vasagrande Testifirma</ButtonLink>
              </li>
            </ul>
          </div>
        </FlexCol>
      </FlexRow>
      <LoadingModal isOpen={loading} contentLabel={"Loading"} message={"Loading..."}/>
    </div>
  )
}

export default LoginView;