import { DID, IndyCredInfo, PingRequestResponse, V10CredentialExchange, V10PresentationExchange, V20CredExRecordDetail, V20PresExRecord, VCRecord } from "./AcaPyModels";
import { WC3VerifiableCredential } from "./WaltidModels";

export interface BasicMessageWrapper {
  message: BasicMessage,
  isRead: boolean
}

export interface BasicMessage {
  connection_id: string,
  message_id: string,
  content: string,
  state: string,
  sent_time: string
}

export interface AvailableCredential {
  cred_def_id: string,
  title: string,
  description: string
}

export interface AvailableRecord {
  record_id: string,
  label: string,
  description: string
}

export const CredentialExhangeReadyStates = ["credential_received", "credential_acked", "credential_revoked", "abandoned", "deleted"];

export enum Issuer {
  COMPANYREGISTER = "companyRegister",
  VERO = "vero",
}

export enum CredentialType {
  INDY_CREDENTIAL_V1 = "IndyCredentialV1",
  INDY_CREDENTIAL_V2 = "IndyCredentialV2",
  ACAPY_W3C_CREDENTIAL = "AcaPyW3CCredential",
  WALTID_W3C_CREDENTIAL = "WaltidW3CCredential"
}

export interface AppContextCredential {
  type: CredentialType,
  createdAt?: string,
  credential: IndyCredInfo|VCRecord|WC3VerifiableCredential,
  exchange?: V10CredentialExchange|V20CredExRecordDetail
}

export interface CredentialResponse {
  credential?: IndyCredInfo,
  w3cCredential?: VCRecord,
  exchange?: V10CredentialExchange,
  v20Exchange?: V20CredExRecordDetail
}

export interface CredentialExchangeResponses {
  v10results: V10CredentialExchange[],
  v20results: V20CredExRecordDetail[]
}

export interface PresentationResponses {
  v10results: V10PresentationExchange[],
  v20results: V20PresExRecord[]
}

export interface WalletInfo {
  ledger: LedgerConfig,
  dids: DID[],
  publicDID?: DID,
  webDID?: string
}

export interface LedgerConfig {
  ID: number,
  Label: string,
  LedgerURL: string,
  GenesisURL: string
}

export enum ConnectionMethod {
  SINGLE_USE_INVITATION = "single-use-invitation",
  MULTI_USE_INVITATION = "multi-use-invitation",
  PUBLIC_DID = "public-did",
  RECEIVE_INVITATION = "receive-invitation"
}

export enum PresentationAttributeRestrictions {
  SCHEMA_ID = "schema_id",
  SCHEMA_ISSUER_DID = "schema_issuer_did",
  SCHEMA_NAME = "schema_name",
  SCHEMA_VERSION = "schema_version",
  ISSUER_DID = "issuer_did",
  CRED_DEF_ID = "cred_def_id",
  ATTRIBUTE_NAME = "attribute_name"
}

export interface PingResponse {
  connection_id: string,
  response?: PingRequestResponse,
  created_at: string
}
