import React, { useEffect, useState, PropsWithChildren, useContext } from "react";
import log from "loglevel";
import _ from "lodash";
import * as CloudWalletApi from '../api/CloudWalletApi';
import * as OmaYritysApi from "../api/OmaYritysApi";
import * as WaltidWalletApi from "../api/WaltidWalletApi";
import * as AcaPyTypes from '../models/AcaPyModels';
import useInterval from "../utils/useInterval";
import { Company } from "../models/OmaXModels";
import { AppContextCredential, AvailableRecord, BasicMessageWrapper, CredentialExchangeResponses, CredentialResponse, CredentialType, PresentationResponses, WalletInfo } from "../models/CloudWalletTypes";
import { AxiosError } from "axios";
import { WC3VerifiableCredential, WaltidUserInfo } from "../models/WaltidModels";
import { getCredentialId } from "../utils/walletUtils";

interface AppState {
  isLoadingApp: boolean,
  isWalletLoaded: boolean,
  company?: Company,
  walletInfo?: WalletInfo|null,
  connections: AcaPyTypes.ConnRecord[],
  connectionPings: {[connection_id: string]: AcaPyTypes.PingRequestResponse|undefined|null},
  messages: { [connection_id: string]: BasicMessageWrapper[]},
  credentials: AppContextCredential[],
  credentialExchanges?: CredentialExchangeResponses|null,
  credentialOffers?: AcaPyTypes.V10CredentialExchange[]|null,
  presentationExchanges?: PresentationResponses|null,
  schemas: { [schema_id: string]: AcaPyTypes.Schema},
  credentialDefinitions: { [cred_def_id: string]: AcaPyTypes.CredentialDefinition},
  availableSchemas: AvailableRecord[]|undefined|null,
  availableCredDefs: AvailableRecord[]|undefined|null,
  walletError?: WalletError,
  waltidUserInfo: WaltidUserInfo|undefined|null,
  waltidCredentials: WC3VerifiableCredential[]|undefined|null
}

export type AppStateType = AppState & {
  // Functions
  setCompany: (company: Company) => void,
  getConnectionsAsync: () => Promise<AcaPyTypes.ConnRecord[]>,
  getActiveConnections: () => AcaPyTypes.ConnRecord[],
  fetchConnectionAsync: (connectionId: string) => Promise<AcaPyTypes.ConnRecord|undefined|null>,
  acceptConnectionAsync: (inviteUrl: string) => Promise<AcaPyTypes.ConnRecord|undefined|null>,
  pingConnectionsAsync: (connRecords: AcaPyTypes.ConnRecord[]) => Promise<{[connection_id: string]: AcaPyTypes.PingRequestResponse|undefined|null}>,
  getBasicMessagesAsync: () => Promise<BasicMessageWrapper[]>,
  getCredentialsAsync: () => Promise<AppContextCredential[]>,
  getCredentialExchangesAsync: () => Promise<CredentialExchangeResponses|undefined|null>,
  getPresentationExchangesAsync: () => Promise<PresentationResponses|undefined|null>,
  getV10PresentationExchangeAsync: (presExId: string) => Promise<AcaPyTypes.V10PresentationExchange|undefined|null>,
  sendPresentationRequestAsync: (payload: AcaPyTypes.V10PresentationSendRequestRequest) => Promise<AcaPyTypes.V10PresentationExchange|undefined|null>,
  getCreatePublicDidConnection: (did: string, alias?: string) => Promise<AcaPyTypes.ConnRecord|undefined|null>,
  createInvitationAsync: (multiUse: boolean, alias?: string) => Promise<AcaPyTypes.InvitationResult|undefined|null>,
  getSchemaAsync: (schemaId: string) => Promise<AcaPyTypes.Schema|undefined|null>,
  getCredentialDefinitionAsync: (credDefId: string) => Promise<AcaPyTypes.CredentialDefinition|undefined|null>,
  getAvailableSchemasAsync: () => Promise<AvailableRecord[]|undefined|null>,
  getAvailableCredDefsAsync: () => Promise<AvailableRecord[]|undefined|null>,
  refreshWalletState: () => Promise<void>,
  resetWalletState: () => Promise<void>,
  loginToWaltidWallet: () => Promise<WaltidUserInfo|undefined|null>,
  getWaltidCredentials: () => Promise<WC3VerifiableCredential[]|undefined|null>
  logout: () => Promise<void>,
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  isWalletLoaded: false,
  company: undefined,
  walletInfo: undefined,
  connections: [],
  connectionPings: {},
  messages: {},
  credentials: [],
  credentialExchanges: undefined,
  credentialOffers: undefined,
  presentationExchanges: undefined,
  schemas: {},
  credentialDefinitions: {},
  availableSchemas: undefined,
  availableCredDefs: undefined,
  walletError: undefined,
  waltidUserInfo: undefined,
  waltidCredentials: undefined
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  setCompany: (company: Company) => { return; },
  getConnectionsAsync: () => new Promise<AcaPyTypes.ConnRecord[]>(resolve => resolve([])),
  getActiveConnections: () => [],
  fetchConnectionAsync: (connectionId: string) => new Promise<AcaPyTypes.ConnRecord|undefined|null>(resolve => resolve(null)),
  acceptConnectionAsync: (inviteUrl: string) => new Promise<AcaPyTypes.ConnRecord|undefined|null>(resolve => resolve(null)),
  pingConnectionsAsync: (connRecords: AcaPyTypes.ConnRecord[]) => new Promise<{[connection_id: string]: AcaPyTypes.PingRequestResponse|undefined|null}>(() => {}),
  getBasicMessagesAsync: () => new Promise<BasicMessageWrapper[]>(resolve => resolve([])),
  getCredentialsAsync: () => new Promise<AppContextCredential[]>(resolve => resolve([])),
  getCredentialExchangesAsync: () => new Promise<CredentialExchangeResponses|undefined|null>(resolve => resolve(null)),
  getPresentationExchangesAsync: () => new Promise<PresentationResponses|undefined|null>(resolve => resolve(null)),
  getV10PresentationExchangeAsync: (presExId: string) => new Promise<AcaPyTypes.V10PresentationExchange|undefined|null>(resolve => resolve(null)),
  sendPresentationRequestAsync: (payload: AcaPyTypes.V10PresentationSendRequestRequest) => new Promise<AcaPyTypes.V10PresentationExchange|undefined|null>(resolve => resolve(null)),
  getCreatePublicDidConnection: (did: string, alias?: string) => new Promise<AcaPyTypes.ConnRecord|undefined|null>(resolve => resolve(null)),
  createInvitationAsync: (multiUse: boolean, alias?: string) => new Promise<AcaPyTypes.InvitationResult|undefined|null>(resolve => resolve(null)),
  getSchemaAsync: (schemaId: string) => new Promise<AcaPyTypes.Schema|undefined|null>(resolve => resolve(undefined)),
  getCredentialDefinitionAsync: (credDefId: string) => new Promise<AcaPyTypes.CredentialDefinition|undefined|null>(resolve => resolve(undefined)),
  getAvailableSchemasAsync: () => new Promise<AvailableRecord[]|undefined|null>(() => {}),
  getAvailableCredDefsAsync: () => new Promise<AvailableRecord[]|undefined|null>(() => {}),
  refreshWalletState: () => new Promise<void>(resolve => resolve()),
  resetWalletState: () => new Promise<void>(resolve => resolve()),
  loginToWaltidWallet: () => new Promise<WaltidUserInfo|undefined|null>(() => {}),
  getWaltidCredentials: () => new Promise<WC3VerifiableCredential[]|undefined|null>(() => []),
  logout: () => new Promise<void>(resolve => resolve()),
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

enum PromiseTypes {
  CURRENT_COMPANY = "currentCompany",
  CONNECTIONS = "connections",
  BASIC_MESSAGES = "basicMessages",
  CREDENTIALS = "credentials",
  CREDENTIAL_EXCHANGES = "credentialExchanges",
  PRESENTATION_EXCHANGES = "presentationExchanges",
  PRESENTATION_EXCHANGE = "presentationExchange",
  PUBLIC_DID_CONNECTION = "publicDidConnection",
  SCHEMA = "schema",
  CREDENTIAL_DEFINITIONS = "credentialDefinitions",
  AVAILABLE_SCHEMAS = "availableSchemas",
  AVAILABLE_CRED_DEFS = "availableCredDefs",
  WALTID_LOGIN= "waltidLogin",
  WALTID_CREDENTIALS="waltidCredentials"
}

enum WalletError {
  LOADING_ERROR = "loadingError",
  WALLET_NOT_EXISTS = "walletNotExists"
}

/**
 * AppContextProvider contains "public" getter methods and "private" fetch methods e.g. getCompanyAsync and fetchCompany
 * When fetching data from APIs, ongoing request promises are stored in the *promises* state. Getter methods (e.g. getCompanyAsync) 
 * return the existing request promise if one exists. If it does not exist, it calls fetch method (e.g. fetchCompany) 
 * to create a new request promise. The idea is to prevent concurrent requests to fetch the same data.
 * Methods that have a comment "public" are accessible outside of the AppContextProvider.
 * Methods that have a comment "private" are for internal use of the AppContextProvider only.
 */
const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  const [refreshWalletInterval, setRefreshWalletInterval] = useState<boolean>(false);
  const [refreshCredExsInterval, setRefreshCredExsInterval] = useState<boolean>(false);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }

  // Run this useEffect only once when app loads
  useEffect(() => {
    loadAppStateAsync();
  }, []);

  // This useEffect will run after login
  useEffect(() => {
    if (appState.company?.id) {
      getWalletInfo(appState.company);
      loginToWaltidWallet();
    }
  }, [appState.company]);


  // useEffect(() => {
  //   console.log("APPCONTEXT_CREDENTIALS", appState.credentials);
  // }, [appState.credentials]);

  // This useEffect will run after login to Waltid wallet
  useEffect(() => {
    if (appState.waltidUserInfo) {
      getWaltidCredentials();
    }
  }, [appState.waltidUserInfo]);

  // Load credentials and verificationActions right after wallet is updated to context
  useEffect(() => {
    if (appState.walletInfo) {
      const connsPromise = getConnectionsAsync();
      const msgPromise = getBasicMessagesAsync();
      const credsPromise = getCredentialsAsync();
      const credExPromise = getCredentialExchangesAsync();
      const presExPromise = getPresentationExchangesAsync();
      // getVerificationActions(cloudWalletState.wallet.walletId);
      Promise.all([connsPromise, msgPromise, credsPromise, credExPromise, presExPromise])
      .then(() => setAppState(oldState => ({...oldState, isWalletLoaded: true})));
    }
  }, [appState.walletInfo]);
  
  // private
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    // Reset necessary stuff in app state
    setAppState(oldState => ({...oldState, isLoadingApp: true}));
    getCurrentCompanyAsync()
    .then(company => {
      setAppState(oldState => ({...oldState, isLoadingApp: false}));
    })
    .catch(err => {
      if (err !== "Not logged in") {
        logger.error("Error", err);
      }
      setAppState(appState => ({...appState, isLoadingApp: false, company: undefined}));
    });
  }

  // private
  const loadWalletStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadWalletStateAsync");
    if (appState.walletInfo) {
      getConnectionsAsync();
      getBasicMessagesAsync();
      getCredentialsAsync();
      getCredentialExchangesAsync();
      getPresentationExchangesAsync();
      // getVerificationActions(cloudWalletState.wallet.walletId);
    }
    if (appState.waltidUserInfo) {
      getWaltidCredentials();
    }
  }

  // public
  const setCompany = (company: Company) => {
    setAppState(oldState => ({...oldState, company}));
  }

  // private
  const getCurrentCompanyAsync = async (): Promise<Company|undefined> => {
    return promises[PromiseTypes.CURRENT_COMPANY] ?? fetchCurrentCompany();
  }

  // private
  const fetchCurrentCompany = async (): Promise<Company|undefined> => {
    const promise = OmaYritysApi.GetCurrentLogin()
    .then(res => {
      if (res.data) {
        setAppState(oldState => ({...oldState, company: res.data}));
        return res.data;
      }
      logger.debug("ACP fetchCurrentCompany response without a company", res);
      return undefined;
    })
    .catch(err => {
      logger.debug("ACP fetchCurrentCompany catched an error", err);
      return undefined;
    })
    .finally(() => removePromise(PromiseTypes.CURRENT_COMPANY));
    addPromise(PromiseTypes.CURRENT_COMPANY, promise);
    return promise;
  }

  // private
  const getWalletInfo = async (company: Company) => {
    CloudWalletApi.GetWalletInfo(company.id)
    .then(walletResp => {
      if (walletResp.data) {
        logger.debug("getWalletInfo", walletResp.data)
        setAppState(oldState => ({...oldState, walletInfo: walletResp.data}));
      }
    })
    .catch(error => {
      const err = error as AxiosError;
      let errorMsg = WalletError.LOADING_ERROR;
      if (err.response?.data === "Wallet config does not exist") {
        errorMsg = WalletError.WALLET_NOT_EXISTS;
      }
      setAppState(oldState => ({...oldState, walletError: errorMsg}));
    });
  }

  // public
  const getConnectionsAsync = async (): Promise<AcaPyTypes.ConnRecord[]> => {
    if (appState.company?.id) {
      return promises[PromiseTypes.CONNECTIONS] ?? fetchConnections(appState.company?.id);
    }
    return new Promise(resolve => resolve([]));
  }

  // private
  const fetchConnections = async (companyID: string): Promise<AcaPyTypes.ConnRecord[]> => {
    const promise = CloudWalletApi.ListConnections(companyID)
    .then(res => {
      if (res.data?.results) {
        setAppState(oldState => ({...oldState, connections: res.data?.results ?? []}));
        const connsToPing = res.data?.results.filter(c => c.state === "active").filter(c => appState.connectionPings[c.connection_id!] === undefined);
        if (connsToPing.length > 0) {
          pingConnectionsAsync(connsToPing);
        }
        return res.data?.results ?? [];
      }
      return [];
    })
    .catch(err => {
      return [];
    })
    .finally(() => removePromise(PromiseTypes.CONNECTIONS));
    addPromise(PromiseTypes.CONNECTIONS, promise);
    return promise;
  }

  // public
  const getActiveConnections = (): AcaPyTypes.ConnRecord[] => {
    return appState.connections.filter(c => c.state === "active");
  }

  // public
  const fetchConnectionAsync = async (connectionId: string): Promise<AcaPyTypes.ConnRecord|undefined|null> => {
    const promise = CloudWalletApi.GetConnection(appState.company!.id, connectionId)
    .then(res => {
      logger.debug("fetchConnectionAsync response", res);
      if (res.data) {
        return res.data;
      }
      return null;
    })
    .catch(err => {
      logger.error("ACP fetchConnectionAsync error", err);
      return undefined;
    });
    return promise;
  }

  // public
  const acceptConnectionAsync = async (inviteUrl: string): Promise<AcaPyTypes.ConnRecord|undefined|null> => {
    const promise = CloudWalletApi.AcceptConnection(appState.company!.id, inviteUrl)
    .then(res => {
      logger.debug("acceptConnectionAsync response", res);
      if (res.data) {
        return res.data;
      }
      return null;
    })
    .catch(err => {
      logger.error("ACP acceptConnectionAsync error", err);
      return undefined;
    });
    return promise;
  }

  // public
  const pingConnectionsAsync = async (connRecords: AcaPyTypes.ConnRecord[]): Promise<{[connection_id: string]: AcaPyTypes.PingRequestResponse|undefined|null}> => {
    const connectionIds = connRecords.map(it => it.connection_id!);
    const promise = CloudWalletApi.PingConnections(connectionIds)
    .then(res => {
      if (res.data) {
        let pingsMap: {[connection_id: string]: AcaPyTypes.PingRequestResponse|undefined|null} = {};
        res.data.map(it => pingsMap = {...pingsMap, [it.connection_id]: it.response ?? null});
        setAppState(appState => ({...appState, connectionPings: {...appState.connectionPings, ...pingsMap}}));
        return pingsMap;
      }
      return {};
    })
    .catch(err => {
      logger.error("ACP pingConnectionAsync error", err);
      return {};
    });
    return promise;
  }

  // public
  const getBasicMessagesAsync = async (): Promise<BasicMessageWrapper[]> => {
    if (appState.company?.id) {
      return promises[PromiseTypes.BASIC_MESSAGES] ?? fetchBasicMessages(appState.company?.id);
    }
    return new Promise(resolve => resolve([]));
  }

  // private
  const fetchBasicMessages = async (companyID: string): Promise<BasicMessageWrapper[]> => {
    const promise = CloudWalletApi.GetBasicMessages(companyID)
    .then(res => {
      if (res.data) {
        let messagesMap: { [connection_id: string]: BasicMessageWrapper[]} = {};
        res.data.forEach(msg => messagesMap = {...messagesMap, [msg.message.connection_id]: [...messagesMap[msg.message.connection_id] ?? [], msg]});
        Object.values(messagesMap).forEach(array => array.sort((a, b) => {return (new Date(b.message.sent_time)).getTime() - (new Date(a.message.sent_time)).getTime()}));
        setAppState(oldState => ({...oldState, messages: messagesMap}));
        return res.data;
      }
      return [];
    })
    .catch(err => {
      return [];
    })
    .finally(() => removePromise(PromiseTypes.BASIC_MESSAGES));
    addPromise(PromiseTypes.BASIC_MESSAGES, promise);
    return promise;
  }

  // public
  const getCredentialsAsync = async (): Promise<AppContextCredential[]> => {
    if (appState.company?.id) {
      return promises[PromiseTypes.CREDENTIALS] ?? fetchCredentials(appState.company?.id);
    }
    return new Promise(resolve => resolve([]));
  }

  // private
  const fetchCredentials = async (companyID: string): Promise<AppContextCredential[]> => {
    const promise = CloudWalletApi.ListCredentialsWithExchange(companyID)
    .then(res => {
      if (res.data) {
        const creds: AppContextCredential[] = res.data.map(it => {
          return {
            // CredentialResponse item always contains either credential or w3cCredential
            credential: it.credential ?? it.w3cCredential!,
            // w3cCredential is always type ACAPY_W3C_CREDENTIAL, credential is V1 or V2
            type: it.w3cCredential
            ? CredentialType.ACAPY_W3C_CREDENTIAL
            : it.v20Exchange ? CredentialType.INDY_CREDENTIAL_V2 : CredentialType.INDY_CREDENTIAL_V1,
            createdAt: it.v20Exchange ? it.v20Exchange.cred_ex_record?.created_at : it.exchange?.created_at,
            exchange: it.v20Exchange ?? it.exchange
          };
        });
        const newCredIds = creds.map(it => getCredentialId(it));
        setAppState(oldState => {
          const waltidCreds = oldState.credentials.filter(it => it.type === CredentialType.WALTID_W3C_CREDENTIAL);
          return {...oldState, credentials: sortCredentialResponses([...waltidCreds, ...creds])}
        });
        return creds ?? [];
      }
      return [];
    })
    .catch(err => {
      return [];
    })
    .finally(() => removePromise(PromiseTypes.CREDENTIALS));
    addPromise(PromiseTypes.CREDENTIALS, promise);
    return promise;
  }

  const sortCredentialResponses = (resp: AppContextCredential[]) => {
    const sorted = resp.sort((a, b) => {
      const aCreatedAtStr = a.createdAt;
      const aCreatedAt = aCreatedAtStr ? Date.parse(aCreatedAtStr) : undefined;
      const bCreatedAtStr = b.createdAt;
      const bCreatedAt = bCreatedAtStr ? Date.parse(bCreatedAtStr) : undefined;
      if (aCreatedAt && bCreatedAt) {
        return aCreatedAt - bCreatedAt;
      }
      else if (aCreatedAt && !bCreatedAt) {
        return 1;
      }
      else if (!aCreatedAt && bCreatedAt) {
        return -1;
      }
      return 0;
    });
    return sorted;
  }

  // public
  const getCredentialExchangesAsync = async (): Promise<CredentialExchangeResponses|null> => {
    if (appState.company?.id) {
      return promises[PromiseTypes.CREDENTIAL_EXCHANGES] ?? fetchCredentialExchanges(appState.company?.id);
    }
    return new Promise(resolve => resolve(null));
  }

  // private
  const fetchCredentialExchanges = async (companyID: string): Promise<CredentialExchangeResponses|null> => {
    const promise = CloudWalletApi.ListCredentialExchanges(companyID)
    .then(res => {
      if (res.data) {
        const credentialExchanges = res.data;
        const credentialOffers = credentialExchanges.v10results.filter(it => it.state === "offer_received");
        setAppState(oldState => ({...oldState, credentialExchanges, credentialOffers}));
        return credentialExchanges;
      }
      return null;
    })
    .catch(err => {
      return null;
    })
    .finally(() => removePromise(PromiseTypes.CREDENTIAL_EXCHANGES));
    addPromise(PromiseTypes.CREDENTIAL_EXCHANGES, promise);
    return promise;
  }

  // public
  const getPresentationExchangesAsync = async (): Promise<PresentationResponses|null> => {
    return promises[PromiseTypes.PRESENTATION_EXCHANGES] ?? fetchPresentationExchanges();
  }

  // private
  const fetchPresentationExchanges = async (): Promise<PresentationResponses|null> => {
    const promise = CloudWalletApi.ListPresentationExchanges()
    .then(res => {
      if (res.data) {
        const presentationExchanges = res.data;
        setAppState(oldState => ({...oldState, presentationExchanges}));
        return presentationExchanges;
      }
      return null;
    })
    .catch(err => {
      return null;
    })
    .finally(() => removePromise(PromiseTypes.PRESENTATION_EXCHANGES));
    addPromise(PromiseTypes.PRESENTATION_EXCHANGES, promise);
    return promise;
  }

  // public
  const getV10PresentationExchangeAsync = async (presExId: string): Promise<AcaPyTypes.V10PresentationExchange|null> => {
    return promises[PromiseTypes.PRESENTATION_EXCHANGE] ?? fetchV10PresentationExchange(presExId);
  }

  // private
  const fetchV10PresentationExchange = async (presExId: string): Promise<AcaPyTypes.V10PresentationExchange|null> => {
    const promise = CloudWalletApi.GetV10PresentationExchange(presExId)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return null;
    })
    .catch(err => {
      return null;
    })
    .finally(() => removePromise(PromiseTypes.PRESENTATION_EXCHANGE));
    addPromise(PromiseTypes.PRESENTATION_EXCHANGE, promise);
    return promise;
  }
  
  // public
  const sendPresentationRequestAsync = async (payload: AcaPyTypes.V10PresentationSendRequestRequest): Promise<AcaPyTypes.V10PresentationExchange|undefined|null> => {
    const promise = CloudWalletApi.SendProofRequest(payload)
    .then(res => {
      logger.debug("sendPresentationRequestAsync response", res);
      if (res.data) {
        return res.data;
      }
      return null;
    })
    .catch(err => {
      logger.error("ACP sendPresentationRequestAsync error", err);
      return undefined;
    });
    return promise;
  }

  // public
  const getCreatePublicDidConnection = async (did: string, alias?: string): Promise<AcaPyTypes.ConnRecord|undefined|null> => {
    const promise = CloudWalletApi.GetCreatePublicDidConnection(appState.company!.id, did, alias)
    .then(res => {
      logger.debug("Public DID connection response", res);
      if (res.data) {
        return res.data;
      }
      return null;
    })
    .catch(err => {
      logger.error("ACP getCreatePublicDidConnection error", err);
      return undefined;
    })
    .finally(() => removePromise(PromiseTypes.PUBLIC_DID_CONNECTION));
    addPromise(PromiseTypes.PUBLIC_DID_CONNECTION, promise);
    return promise;
  }

  // public
  const createInvitationAsync = async (multiUse: boolean, alias?: string): Promise<AcaPyTypes.InvitationResult|undefined|null> => {
    const promise = CloudWalletApi.CreateInvitation(appState.company!.id, alias, multiUse)
    .then(res => {
      logger.debug("createInvitationAsync response", res);
      if (res.data) {
        return res.data;
      }
      return null;
    })
    .catch(err => {
      logger.error("ACP createInvitationAsync error", err);
      return undefined;
    });
    return promise;
  }

  // public
  const getSchemaAsync = async (schemaId: string): Promise<AcaPyTypes.Schema|undefined|null> => {
    if (appState.schemas[schemaId]) {
      return appState.schemas[schemaId];
    }
    if (appState.company?.id) {
      return promises[`${PromiseTypes.SCHEMA}-${schemaId}`] ?? fetchSchema(appState.company.id, schemaId);
    }
    return null;
  }

  // private
  const fetchSchema = async (companyID: string, schemaId: string): Promise<AcaPyTypes.Schema|undefined|null> => {
    const promise = CloudWalletApi.GetSchema(companyID, schemaId)
    .then(res => {
      if (res.data && res.data.schema) {
        setAppState(appState => ({...appState, schemas: {...appState.schemas, [schemaId]: res.data!.schema!}}));
        return res.data.schema;
      }
      return null;
    })
    .catch(err => {
      return undefined;
    })
    .finally(() => removePromise(`${PromiseTypes.SCHEMA}-${schemaId}`));
    addPromise(`${PromiseTypes.SCHEMA}-${schemaId}`, promise);
    return promise;
  }

  // public
  const getCredentialDefinitionAsync = async (credDefId: string): Promise<AcaPyTypes.CredentialDefinition|undefined|null> => {
    if (appState.credentialDefinitions[credDefId]) {
      return appState.credentialDefinitions[credDefId];
    }
    const fetch = async (companyID: string, credDefId: string): Promise<AcaPyTypes.CredentialDefinition|undefined|null> => {
      const promise = CloudWalletApi.GetCredentialDefinition(companyID, credDefId)
      .then(res => {
        if (res.data && res.data.credential_definition) {
          setAppState(appState => ({...appState, schemas: {...appState.credentialDefinitions, [credDefId]: res.data!.credential_definition!}}));
          return res.data.credential_definition;
        }
        return null;
      })
      .catch(err => {
        return undefined;
      })
      .finally(() => removePromise(`${PromiseTypes.CREDENTIAL_DEFINITIONS}-${credDefId}`));
      addPromise(`${PromiseTypes.CREDENTIAL_DEFINITIONS}-${credDefId}`, promise);
      return promise;
    }
    if (appState.company?.id) {
      return promises[`${PromiseTypes.CREDENTIAL_DEFINITIONS}-${credDefId}`] ?? fetch(appState.company.id, credDefId);
    }
    return null;
  }

  // public
  const getAvailableSchemasAsync = async (): Promise<AvailableRecord[]|undefined|null> => {
    if (appState.availableSchemas) {
      return appState.availableSchemas;
    }

    const fetch = async (): Promise<AvailableRecord[]|undefined|null> => {
      const promise = CloudWalletApi.GetAvailableSchemas()
      .then(res => {
        if (res.data) {
          setAppState(appState => ({...appState, availableSchemas: res.data}));
          return res.data;
        }
        return null;
      })
      .catch(err => {
        return undefined;
      })
      .finally(() => removePromise(PromiseTypes.AVAILABLE_SCHEMAS));
      addPromise(PromiseTypes.AVAILABLE_SCHEMAS, promise);
      return promise;
    }
    return promises[PromiseTypes.AVAILABLE_SCHEMAS] ?? fetch();
  }

  // public
  const getAvailableCredDefsAsync = async (): Promise<AvailableRecord[]|undefined|null> => {
    if (appState.availableCredDefs) {
      return appState.availableCredDefs;
    }

    const fetch = async (): Promise<AvailableRecord[]|undefined|null> => {
      const promise = CloudWalletApi.GetAvailableCredDefs()
      .then(res => {
        if (res.data) {
          setAppState(appState => ({...appState, availableCredDefs: res.data}));
          return res.data;
        }
        return null;
      })
      .catch(err => {
        return undefined;
      })
      .finally(() => removePromise(PromiseTypes.AVAILABLE_CRED_DEFS));
      addPromise(PromiseTypes.AVAILABLE_CRED_DEFS, promise);
      return promise;
    }
    return promises[PromiseTypes.AVAILABLE_CRED_DEFS] ?? fetch();
  }

  const refreshWalletState = async (): Promise<void> => {
    await loadWalletStateAsync();
  }

  const resetWalletState = async (): Promise<void> => {
    setAppState(() => DefaultAppState);
  }

  const loginToWaltidWallet = async (): Promise<WaltidUserInfo|undefined|null> => {
    return promises[PromiseTypes.WALTID_LOGIN] ?? (
      async (): Promise<WaltidUserInfo|undefined|null> => {
        const promise = WaltidWalletApi.Login()
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, waltidUserInfo: res.data}));
            return res.data;
          }
          logger.debug("ACP loginToWaltidWallet response without UserInfo", res);
          return null;
        })
        .catch(err => {
          logger.debug("ACP loginToWaltidWallet catched an error", err);
          return undefined;
        })
        .finally(() => removePromise(PromiseTypes.WALTID_LOGIN));
        addPromise(PromiseTypes.WALTID_LOGIN, promise);
        return promise;
      }
    )();
  }

  const getWaltidCredentials = async (): Promise<any[]|undefined|null> => {
    return promises[PromiseTypes.WALTID_CREDENTIALS] ?? (
      async (): Promise<any[]|undefined|null> => {
        const promise = WaltidWalletApi.ListCredentials()
        .then(res => {
          if (res.data) {
            const creds: AppContextCredential[] = res.data.map(it => {
              return {
                credential: it.parsedDocument,
                type: CredentialType.WALTID_W3C_CREDENTIAL,
                createdAt: it.addedOn||undefined,
                exchange: undefined
              };
            });
            const newCredIds = creds.map(it => getCredentialId(it));
            setAppState(oldState => {
              const acapyCreds = oldState.credentials.filter(it => it.type !== CredentialType.WALTID_W3C_CREDENTIAL);
              return {...oldState, credentials: sortCredentialResponses([...acapyCreds, ...creds])}
            });
            return creds;
          }
          logger.debug("ACP getWaltidCredentials response without credentials list", res);
          return null;
        })
        .catch(err => {
          logger.debug("ACP getWaltidCredentials catched an error", err);
          return undefined;
        })
        .finally(() => removePromise(PromiseTypes.WALTID_CREDENTIALS));
        addPromise(PromiseTypes.WALTID_CREDENTIALS, promise);
        return promise;
      }
    )();
  }

  const logout = async (): Promise<void> => {
    return OmaYritysApi.LogOut()
    .then(() => {
      return WaltidWalletApi.LogOut();
    })
    .then(() => {
      return resetWalletState();
    })
    .then(() => {
      return loadAppStateAsync();
    });
  }

  useInterval(async () => {
    if (refreshWalletInterval) {
      refreshWalletState();
    }
  }, refreshWalletInterval || refreshCredExsInterval ? 5000 : null);

  return (
    <AppStateContext.Provider value={{
      ...appState,
      setCompany,
      getConnectionsAsync,
      getActiveConnections,
      fetchConnectionAsync,
      acceptConnectionAsync,
      pingConnectionsAsync,
      getBasicMessagesAsync,
      getCredentialsAsync,
      getCredentialExchangesAsync,
      getPresentationExchangesAsync,
      getV10PresentationExchangeAsync,
      sendPresentationRequestAsync,
      getCreatePublicDidConnection,
      createInvitationAsync,
      getSchemaAsync,
      getCredentialDefinitionAsync,
      getAvailableSchemasAsync,
      getAvailableCredDefsAsync,
      refreshWalletState,
      resetWalletState,
      loginToWaltidWallet,
      getWaltidCredentials,
      logout
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppStateContext = () => {
  const appContext = useContext(AppStateContext);
  if (appContext === undefined) {
    throw new Error("useAppStateContext must be used within a AppContextProvider");
  }
  return appContext;
}

export default AppContextProvider;
