import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText } from '@mui/material';
import { toDateTimeString } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router';
import { V10PresentationExchange } from '../../../models/AcaPyModels';
import { getConnectionLabel } from '../../../utils/walletUtils';
import { useAppStateContext } from '../../../state/AppStateContext';

const V10PresExchangeDetails: React.FC<{presEx: V10PresentationExchange, showActions: boolean}> = ({presEx, showActions}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  
  const isProver = () => {
    return presEx.role === V10PresentationExchange.RoleEnum.Prover;
  }

  const getState = () => {
    if (i18n.exists(`app.models.V10PresExRecordState.${presEx.state}`)) {
      let color = "info.main";
      if (presEx.state === V10PresentationExchange.StateEnum.Verified || presEx.state === V10PresentationExchange.StateEnum.PresentationAcked) {
        color = "success.main";
      }
      if (presEx.state === V10PresentationExchange.StateEnum.Abandoned) {
          color = "error.main";
        }
      return <ListItemText primaryTypographyProps={{ color }} primary={t(`app.models.V10PresExRecordState.${presEx.state}`)}/>;
    }
    return <ListItemText primaryTypographyProps={{ color: "error.main"}} primary={t("app.models.V10PresExRecordState.error") + ": " + presEx.state}/>;
  }

  return (
    <Grid container spacing={1} sx={{alignItems: "center"}}>
      <Grid item container xs={12}>
        <Grid item xs={6} md={4} sx={{fontWeight: 700}}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.ActivityView.activityId")}/>
        </Grid>
        <Grid item xs={6} md={8}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={presEx.presentation_exchange_id}/>
        </Grid>
        <Grid item xs={6} md={4} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.name")}
        </Grid>
        <Grid item xs={6} md={8}>
          {presEx.presentation_request?.name}
        </Grid>
        <Grid item xs={6} md={4} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.verifier")}
        </Grid>
        <Grid item xs={6} md={8}>
          {isProver() ? getConnectionLabel(appContext.connections.find( c => c.connection_id === presEx.connection_id)) : appContext.company?.companyName}
        </Grid>
        <Grid item xs={6} md={4} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.prover")}
        </Grid>
        <Grid item xs={6} md={8}>
        {isProver() ? appContext.company?.companyName : getConnectionLabel(appContext.connections.find( c => c.connection_id === presEx.connection_id))}
        </Grid>
        <Grid item xs={6} md={4} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.state")}
        </Grid>
        <Grid item xs={6} md={8}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={getState()}/>
        </Grid>
        <Grid item xs={6} md={4} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.createdAt")}
        </Grid>
        <Grid item xs={6} md={8}>
          {toDateTimeString(presEx.created_at)}
        </Grid>
        <Grid item xs={6} md={4} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.updatedAt")}
        </Grid>
        <Grid item xs={6} md={8}>
          {toDateTimeString(presEx.updated_at)}
        </Grid>
      </Grid>
      { showActions && 
        <Grid item container xs={12}>
          <Button variant="contained" onClick={() => navigate(`/presentation/${presEx.presentation_exchange_id}`)}>
            {t("app.components.PresExchangeDetails.openRequest")}
          </Button>
        </Grid>
      }
    </Grid>
  );
}

export default V10PresExchangeDetails;