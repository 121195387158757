import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link, useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { V10PresentationExchange, V20PresExRecord } from '../../../models/AcaPyModels';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import V10ProofRequestView from './V10ProofRequestView';

const PresentationRequestView: React.FC = () => {
  const navigate = useNavigate();
  const { referent } = useParams();
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [v10presEx, setV10presEx] = useState<V10PresentationExchange|undefined>(undefined);
  const [v20presEx, setV20presEx] = useState<V20PresExRecord|undefined>(undefined);

  useEffect(() => {
    // Set loading to false after 5 seconds even though presentation request is not found.
    setTimeout(() => setLoading(() => false), 5000);
  })

  useEffect(() => {
    appContext.getPresentationExchangesAsync();
  }, []);

  useEffect(() => {
    if (appContext.presentationExchanges) {
      let matchingV10presEx: V10PresentationExchange|undefined = undefined;
      let matchingV20presEx: V20PresExRecord|undefined = undefined;
      matchingV10presEx = appContext.presentationExchanges?.v10results?.find(it => it.presentation_exchange_id === referent);
      if (!matchingV10presEx) {
        matchingV20presEx = appContext.presentationExchanges?.v20results?.find(it => it.pres_ex_id === referent);
      }
      if (matchingV10presEx) {
        setV10presEx(matchingV10presEx);
        // Resresh from server
        appContext.getV10PresentationExchangeAsync(matchingV10presEx.presentation_exchange_id!)
        .then(updatedPresEx => {
          if (updatedPresEx) {
            setV10presEx(() => updatedPresEx);
          }
        });
      }
      else if (matchingV20presEx) {
        setV20presEx(matchingV20presEx);
      }
      else {
        navigate("/404");
      }
      setLoading(() => false);
    }
  }, [appContext.presentationExchanges]);

  const reloadV10PresEx = async (presEx: V10PresentationExchange): Promise<void> => {
    return appContext.getV10PresentationExchangeAsync(presEx.presentation_exchange_id!)
    .then(updatedPresEx => {
      if (updatedPresEx) {
        setV10presEx(() => updatedPresEx);
        console.log("reloadV10PresEx done");
      }
    });
  }

  return (
    <Page 
    title={t("app.components.ProofRequestView.title")}
    breadcrumbs={[
      { href: "/", label: "frontpage", current: false},
      { href: "/presentations", label: "presentations", current: false},
      { href: "/presentation/:referent", label: "presentation", current: true}
    ]}
    titleAction={
      <Button 
        startIcon={loading ? <HourglassEmptyIcon/> : <RefreshIcon/>}
        onClick={() => {v10presEx && reloadV10PresEx(v10presEx)}}
      >
        {loading ? t("common.actions.refreshing.title") : t("common.actions.refresh.title")}
      </Button>
    }
    >
      { !loading && v10presEx && <V10ProofRequestView presEx={v10presEx} reload={async () => reloadV10PresEx(v10presEx)}/> }
      { !loading && v20presEx && <></> }
      { !loading && !v10presEx && !v20presEx && 
        <Link to={"/"}>{t("app.components.PageNotFoundView.description")}</Link>
      }
      { loading && <span>Loading...</span> }
    </Page>
  );
}

export default PresentationRequestView;