import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Company } from '../models/OmaXModels';

const apiRootUrl = process.env.REACT_APP_OMAYRITYS_FUNCTIONS_URL || 'http://localhost:7071/api'
const apiKey = process.env.REACT_APP_OMAYRITYS_FUNCTIONS_API_KEY || "";
const companyTokenKey = "x-minisuomi-company-token";

const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  const token = sessionStorage.getItem(companyTokenKey);
  if (token && config.headers) {
    config.headers[companyTokenKey] = token;
  }
  return config;
}

const responseInterceptor = (response: AxiosResponse<any>) => {
  const token = response.headers[companyTokenKey];
  if (token) {
    sessionStorage.setItem(companyTokenKey, token);
  }
  return response;
}

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
  withCredentials: true
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(responseInterceptor);

export async function Login(businessId: string): Promise<AxiosResponse<Company>> {
  return api.post("/CompanyWallet/Login", null, {params: {bid: businessId}});
}

export async function GetCurrentLogin(): Promise<AxiosResponse<Company>> {
  const token = sessionStorage.getItem(companyTokenKey);
  if (token) {
    return api.get("/CompanyWallet/GetCurrentLogin");
  }
  return new Promise((resolve, reject) => reject("Not logged in"));
}

export async function LogOut(): Promise<any> {
  sessionStorage.removeItem(companyTokenKey);
  return new Promise(resolve => resolve("Logged out"));
}


