import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, FormControl, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { IndyCredPrecis, IndyPresSpec, IndyProofReqAttrSpec, IndyRequestedCredsRequestedAttr, V10PresentationExchange, V10PresentationProblemReportRequest, V10PresentationSendRequestRequest } from '../../../models/AcaPyModels';
import { useAppStateContext } from '../../../state/AppStateContext';
import V10PresExchangeDetails from '../Activity/V10PresExchangeDetails';
import * as CloudWalletApi from '../../../api/CloudWalletApi';

const RequestedAttributeLabel: React.FC<{label: string}> = ({label}): React.ReactElement => {
  return <ListItemText primaryTypographyProps={{variant: "button", marginY: "unset"}} primary={label}/>;
}

const RequestedAttributeSubLabel: React.FC<{name: string}> = ({name}): React.ReactElement => {
  return <ListItemText primaryTypographyProps={{fontWeight: 700}} sx={{marginY: "unset"}} primary={name}/>;
}

const AttributeName: React.FC<{name: string}> = ({name}): React.ReactElement => {
  return <ListItemText primaryTypographyProps={{fontWeight: 700}} sx={{paddingRight: tokens.spacing.s, paddingLeft: {xs: tokens.spacing.xxs, sm: "unset"}, marginY: "unset", display: "flex", justifyContent: {xs: "flex-start", sm: "flex-end"}}} primary={name}/>;
}

const WordBrakeValue: React.FC<{value?: string}> = ({value}): React.ReactElement => {
  return <ListItemText sx={{wordBreak: "break-all", marginY: "unset", paddingLeft: {xs: tokens.spacing.xs, sm: "unset"}}} primary={value}/>;
}

const ErrorText: React.FC<{value: string}> = ({value}): React.ReactElement => {
  return <ListItemText primaryTypographyProps={{variant: "button", color: tokens.colors.alertBase, marginY: "unset"}} primary={value}/>;
}

interface V10ProofSelectProps {
  attributeKey: string,
  selected?: IndyCredPrecis,
  options: IndyCredPrecis[],
  onSelect: (attrKey: string, selected: IndyCredPrecis) => void
}

const V10ProofSelect: React.FC<V10ProofSelectProps> = ({attributeKey, selected, options, onSelect}) => {
  const { t } = useTranslation();
  
  const onChange = (referent: string | IndyCredPrecis) => {
    const newSelected = referent as IndyCredPrecis;
    onSelect(attributeKey, newSelected);
  }

  return (
    <FormControl fullWidth>
      { !options && 
        <InputLabel id={`V10ProofSelect-${attributeKey}-select-label`} color="error">
          <ErrorText value={t("app.components.ProofRequestView.noAvailableValue")}/>
        </InputLabel>
      }
      <Select
        variant="standard"
        labelId={`V10ProofSelect-${attributeKey}-select-label`}
        value={selected || ""}
        renderValue={value => 
          <ListItemText primary={value.cred_info?.schema_id?.split(':')[2]} secondary={value.cred_info?.referent}/> 
          ?? <ErrorText value={t("app.components.ProofRequestView.noAvailableValue")}/>
        }
        onChange={(event) => onChange(event.target.value)}
        sx={{flexGrow: 1}}
        disabled={!options}
      >
        {!options && <MenuItem value=""><Typography color="error">{t("app.components.ProofRequestView.noAvailableValue")}</Typography></MenuItem>}
        {options?.map((option, index) => (
          //@ts-ignore
          <MenuItem key={`select-${attributeKey}-${index}`} value={option}>
            <ListItemText primary={selected?.cred_info?.schema_id?.split(':')[2]} secondary={option.cred_info?.referent}/>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const AttributeNameValue: React.FC<{key: string, name: string, value?: string}> = ({key, name, value}) => {
  return (
    <Grid key={key} container item xs={12}>
      <Grid item xs={12} sm={6} md={4}>
        <AttributeName name={name}/>
      </Grid>
      <Grid item xs={12} sm={6} md={8} sx={{display: "flex"}}>
        <WordBrakeValue value={value}/>
      </Grid>
    </Grid>
  );
}

const V10ProofRequestView: React.FC<{presEx: V10PresentationExchange, reload: () => Promise<void>}> = ({presEx, reload}) => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  // availableCredentials are fetched from wallet agent and contain credentials that can be used as a presentation to this proof request.
  const [availableCredentials, setAvailableCredentials] = useState<IndyCredPrecis[]>([]);
  // attributeCreds contain availableCredentials but are sorted by the keys of each requested attribute in the proof request.
  const [attributeCreds, setAttributeCreds] = useState<{[attributeKey: string]: IndyCredPrecis[]}>({});
  // selectedProofs contain credentials that will be used when responding to proof request.
  const [selectedProofs, setSelectedProofs] = useState<{ [attributeKey: string]: IndyCredPrecis}>({});

  // When page loads, fetch available credentials from server
  useEffect(() => {
    if (appContext.company?.id) {
      getAvailableCredentials(appContext.company.id, presEx.presentation_exchange_id!);
    }
  }, []);

  // When available credentials are fetched from server, sort them by requested attribute keys and select default values for response.
  useEffect(() => {
    if (availableCredentials.length > 0) {
      let attrCreds: {[attribute: string]: IndyCredPrecis[]} = {};
      Object.entries(presEx.presentation_request!.requested_attributes).forEach(([key, attrSpec]) => {
        const matchingCredentials = availableCredentials.filter(it => it.presentation_referents?.find(r => r === key));
        attrCreds[key] = matchingCredentials;
      });
      setAttributeCreds(attrCreds);
      selectDefaults(attrCreds);
    }
  }, [availableCredentials]);

  const getAvailableCredentials = async (companyID: string, verificationId: string) => {
    const possibleCredentials = await CloudWalletApi.ListAvailableCredentialsForProofRequest(companyID, verificationId);
    if (possibleCredentials.data) {
      console.log("AvailableCredentials", possibleCredentials.data);
      setAvailableCredentials(possibleCredentials.data);
    }
  }

  const selectDefaults = (attrCreds: {[attribute: string]: IndyCredPrecis[]}) => {
    let defaults: { [attribute: string]: IndyCredPrecis} = {};
    Object.entries(attrCreds).forEach(([key, creds]) => {
      defaults[key] = creds[0];
    })
    setSelectedProofs(() => defaults);
  }

  const selectProof = (key: string, selected: IndyCredPrecis) => {
    setSelectedProofs(old => ({...old, [key]: selected}));
  }

  const getRequestedCredentialAttributeNames = (attrSpec: IndyProofReqAttrSpec): string[] => {
    return attrSpec.name ? [attrSpec.name] : attrSpec.names ?? [];
  }

  const getValueForRequestedCredentialAttributeNames = (attributeKey: string, attributeName: string): string|undefined => {
    const attributes = selectedProofs[attributeKey] && selectedProofs[attributeKey].cred_info?.attrs;
    return attributes && attributes[attributeName] ? attributes[attributeName] : "";
  }
  
  const getEditablePresentationForProofAttribute = (attrKey: string, attrSpec: IndyProofReqAttrSpec): React.ReactElement[] => {
    if (!selectedProofs[attrKey]) {
      if (attributeCreds[attrKey]) {
        return [
          <Grid key={`V10ProofResponse-${attrKey}`} container item xs={12}>
            <ErrorText value={t("app.components.ProofRequestView.selectCredential")}/>
          </Grid>
        ];
      }
      return [
        <Grid key={`V10ProofResponse-${attrKey}`} container item xs={12}>
          <ErrorText value={t("app.components.ProofRequestView.noAvailableValue")}/>
        </Grid>
      ];
    }
    return getRequestedCredentialAttributeNames(attrSpec).map((attrName, attrNameIndex) => (
      <AttributeNameValue 
        key={`V10ProofResponse-${attrKey}-${attrName}-${attrNameIndex}`}
        name={attrName}
        value={getValueForRequestedCredentialAttributeNames(attrKey, attrName)}
      />
    ));
  }

  const getRevealedAttrsByAttrKey = (attrKey: string) => {
    const revealedAttrs = presEx.presentation?.requested_proof?.revealed_attrs;
    return revealedAttrs ? revealedAttrs[attrKey] : undefined;
  }

  const getRevealedAttrGroupsByAttrKey = (attrKey: string) => {
    const revealedAttrGroups = presEx.presentation?.requested_proof?.revealed_attr_groups;
    return revealedAttrGroups ? revealedAttrGroups[attrKey] : undefined;
  }

  const showPresentationByAttrKey = (attrKey: string, attrSpec: IndyProofReqAttrSpec) => {
    const revealedAttr = getRevealedAttrsByAttrKey(attrKey);
    const revealedAttrGroup = getRevealedAttrGroupsByAttrKey(attrKey);
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <RequestedAttributeLabel label={t("app.components.ProofRequestView.response")}/>
        </Grid>
        { revealedAttr && 
          <AttributeNameValue 
            key={`RevAttr-${attrKey}`}
            name={attrSpec.name!}
            value={revealedAttr.raw}
          />
        }
        { revealedAttrGroup && revealedAttrGroup.values && 
          Object.entries(revealedAttrGroup.values).map(([attrName, rawEncodedValue], attrNameIndex) => (
            <AttributeNameValue 
              key={`RevAttrGrp-${attrKey}-${attrName}-${attrNameIndex}`}
              name={attrName}
              value={rawEncodedValue.raw}
            />
          ))
        }
      </Grid>
    );
  }

  const showPresentationForProofAttribute = (attrKey: string, attrSpec: IndyProofReqAttrSpec): React.ReactElement => {
    switch (presEx.state) {
      case V10PresentationExchange.StateEnum.RequestReceived:
        // Role is prover and proof request is in edit mode. Show selected presentation or a nofitication.
        return (
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <RequestedAttributeLabel label={t("app.components.ProofRequestView.response")}/>
            </Grid>
            { isEditable() && getEditablePresentationForProofAttribute(attrKey, attrSpec) }
          </Grid>
        );
      
      case V10PresentationExchange.StateEnum.RequestSent:
        // Role is verifier and at this state of proof request there is no presentation responses.
        return <></>;
      
      case V10PresentationExchange.StateEnum.PresentationReceived:
      case V10PresentationExchange.StateEnum.PresentationAcked:
      case V10PresentationExchange.StateEnum.Verified:
        // Role is prover (PresentationAcked) or verifier (Verified).
        // At these states the proof request should contain presentations for all requested attributes.
        return showPresentationByAttrKey(attrKey, attrSpec);
      default:
        return <></>;
    }
    
  }

  const sendPresentation = () => {
    let proof_request:{[attrKey: string]: IndyRequestedCredsRequestedAttr} = {};
    Object.entries(selectedProofs).forEach(([key, value])  => {
      if (value?.cred_info?.referent) {
        const pres: IndyRequestedCredsRequestedAttr = {
          cred_id: value.cred_info.referent!,
          revealed: true
        }
        proof_request = {...proof_request, [key]: pres};
      }
    })
    const presentation: IndyPresSpec = {
      requested_attributes: proof_request,
      requested_predicates: {},
      self_attested_attributes: {}
    }
    console.log("Sending presentation", presentation);
    CloudWalletApi.SendPresentation(presEx.presentation_exchange_id!, presentation)
    .then(() => {
      console.log("SendPresentation done, reloading");
      return reload();
    })
    .then(() => {
      console.log("SendPresentation done, reload done");
    });
  }

  const rejectProofRequest = () => {
    let problemReport: V10PresentationProblemReportRequest = {
      description: "Rejected"
    };
    console.log("Rejecting proof request", problemReport);
    CloudWalletApi.RejectProofRequest(presEx.presentation_exchange_id!, problemReport)
    .then(() => {
      console.log("RejectProofRequest done, reloading");
      return reload();
    })
    .then(() => {
      console.log("RejectProofRequest done, reload done");
    });
  }

  const verifyPresentation = () => {
    CloudWalletApi.VerifyPresentation(presEx.presentation_exchange_id!)
    .then(() => {
      return reload();
    });
  }

  const isEditable = () => {
    return presEx.state === V10PresentationExchange.StateEnum.RequestReceived;
  }

  return (
    <Paper elevation={3} sx={{padding: tokens.spacing.xs, display: "flex", flexDirection: "column"}}>
      <V10PresExchangeDetails presEx={presEx} showActions={false}/>
      <Box sx={{marginY: tokens.spacing.s}}>
        <Typography variant="button">
          {t("app.components.ProofRequestView.sublabel")}
        </Typography>
      </Box>
      <Paper elevation={3} sx={{backgroundColor: "#F9FAFB"}}>
        {Object.entries(presEx.presentation_request!.requested_attributes).map(([attrKey, attrSpec], attrIndex) => (
          <>
            <Grid container item key={`pres-req-attr-${attrIndex}`} sx={{alignItems: "center", padding: tokens.spacing.xs}}>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6} md={4}>
                  <RequestedAttributeLabel label={t("app.components.ProofRequestView.attributeLabel")}/>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <WordBrakeValue value={attrKey}/>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={4}>
                    <RequestedAttributeSubLabel name={t("app.components.ProofRequestView.attributeNames")}/>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    { attrSpec.name && <WordBrakeValue value={attrSpec.name}/>}
                    { attrSpec.names && <WordBrakeValue value={attrSpec.names.join(", ")}/>}
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <RequestedAttributeSubLabel name={t("app.components.ProofRequestView.restrictions.title")}/>
                  </Grid>
                  { attrSpec.restrictions?.map((r, i) => {
                    return Object.entries(r).map(([rKey, value], rIndex) => (
                      <Grid key={`restrictions-${attrKey}-${i}-${rKey}-${rIndex}`} container item xs={12}>
                        <Grid item xs={12} sm={6} md={4}>
                          <AttributeName name={t(`app.components.ProofRequestView.restrictions.${rKey}`)}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                          <WordBrakeValue value={value}/>
                        </Grid>
                      </Grid>
                    ))
                  })}
                </Grid>
              </Grid>
              { isEditable() &&
                <Grid container item xs={12}>
                  <Grid item xs={12} md={4}>
                    <RequestedAttributeLabel label={t("app.components.ProofRequestView.selectCredential")}/>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{display: "flex"}}>
                    <V10ProofSelect attributeKey={attrKey} selected={selectedProofs[attrKey]} options={attributeCreds[attrKey]} onSelect={selectProof}/>
                  </Grid>
                </Grid>
              }
              { showPresentationForProofAttribute(attrKey, attrSpec) }
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        ))}
      </Paper>
      { isEditable() && 
        <>
          <Box sx={{marginTop: tokens.spacing.s}}>
            <Typography variant="button">
              {t("app.components.ProofRequestView.actionslabel")}
            </Typography>
          </Box>
          <Box sx={{marginTop: tokens.spacing.s, display: "flex", justifyContent: "space-between"}}>
            <Button variant="contained" color="error" onClick={() => rejectProofRequest()}>
              {t("common.actions.reject.title")}
            </Button>
            <Button variant="contained" color="primary" onClick={() => sendPresentation()}>
              {t("common.actions.accept.title")}
            </Button>
          </Box>
        </>
      }
      { presEx.state === V10PresentationExchange.StateEnum.PresentationReceived &&
        <>
          <Box sx={{marginTop: tokens.spacing.s}}>
            <Typography variant="button">
              {t("app.components.ProofRequestView.acceptLabel")}
            </Typography>
          </Box>
          <Box sx={{marginTop: tokens.spacing.s, display: "flex", justifyContent: "space-between"}}>
            <Button variant="contained" color="error" onClick={() => rejectProofRequest()}>
              {t("common.actions.reject.title")}
            </Button>
            <Button variant="contained" color="primary" onClick={() => verifyPresentation()}>
              {t("common.actions.accept.title")}
            </Button>
          </Box>
        </>
      }

    </Paper>
  );
}

export default V10ProofRequestView;