import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Collapse, Grid, ListItemButton, ListItemText, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../../state/AppStateContext';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import Page from '../../common/Page';
import { ConnRecord } from '../../../models/AcaPyModels';
import { BreadcrumbItem } from '../../common/Breadcrumbs';
import ChatView from './ChatView';
import { getConnectionLabel } from '../../../utils/walletUtils';

const ConnectionsSideMenu: React.FC<{activeConnId?: string}> = ({activeConnId}) => {
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  return (
    <Paper elevation={3} sx={{backgroundColor: "#F9FAFB", maxHeight: "500px", overflow: "auto"}}>
      <MenuList>
        { appContext.getActiveConnections().map((connRecord, index) => 
        <MenuItem 
          key={`connsidemenuitem-${index}`} 
          selected={connRecord.connection_id === activeConnId} 
          onClick={() => navigate(`/messages/${connRecord.connection_id}`)}
        >
          <Box>
          <Button 
            fullWidth
            sx={{color: `${tokens.colors.brandBase}`, whiteSpace: "normal", textAlign: "left"}}
          >
            {getConnectionLabel(connRecord)} 
          </Button>
          </Box>
          
        </MenuItem>
        )}
      </MenuList>
    </Paper>
  );
}

const ConnectionsMobileMenu: React.FC<{activeConnection?: ConnRecord}> = ({activeConnection}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  const [checked, setChecked] = React.useState(false);

  const getSelectedConnection = () => {
    const selectedConn = activeConnection && appContext.getActiveConnections().find(c => c.connection_id === activeConnection.connection_id);
    return selectedConn ? getConnectionLabel(selectedConn) : t("app.components.BasicMessagesView.selectConnection");
  }

  const onSelect = (path: string) => {
    setChecked(old => false);
    navigate(path);
  }

  return (
    <Paper elevation={3} sx={{backgroundColor: "#F9FAFB", mb: tokens.spacing.s, display: "flex", flexDirection: "column"}}>
      <Button
        fullWidth
        size="large"
        endIcon={<KeyboardArrowDownIcon/>}
        onClick={() => setChecked(old => !old)}
        sx={{color: `${tokens.colors.brandBase}`}}
      >
        {getSelectedConnection()}
      </Button>
      <Collapse in={checked}>
        <MenuList>
          { appContext.getActiveConnections().map((connRecord, index) => 
            <MenuItem key={`connmobilemenuitem-${index}`} selected={connRecord.connection_id === activeConnection?.connection_id} >
              <ListItemButton disableGutters dense onClick={() => onSelect(`/messages/${connRecord.connection_id}`)}>
                <ListItemText primary={getConnectionLabel(connRecord)} />
              </ListItemButton>
            </MenuItem>
          )}
        </MenuList>
      </Collapse>
    </Paper>
  );
}

const BasicMessagesView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const { referent } = useParams();
  const [activeConnection, setActiveConnection] = useState<ConnRecord|undefined>(undefined);

  useEffect(() => {
    appContext.getConnectionsAsync();
    appContext.getBasicMessagesAsync();
  }, []);

  useEffect(() => {
    setActiveConnection(old => appContext.getActiveConnections().find(c => c.connection_id === referent));
  }, [referent, appContext.connections]);
  

  const getBreadcrumbs = (): BreadcrumbItem[] => {
    if (activeConnection) {
      return [
        { href: "/", label: "frontpage", current: false},
        { href: "/messages", label: "messages", current: false},
        { href: "/messages/:referent", label: "messages", current: true, element: <Typography key={activeConnection.connection_id} variant="button" sx={{color: `${tokens.colors.brandBase}`}}>{getConnectionLabel(activeConnection)}</Typography>}
      ];
    }
    return [
      { href: "/", label: "frontpage", current: false},
      { href: "/messages", label: "messages", current: true}
    ];
  }

  return (
    <Page 
      title={t("app.components.BasicMessagesView.title")}
      breadcrumbs={getBreadcrumbs()}
    >
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} sx={{display: {xs: "none", md: "block"}}}>
            <ConnectionsSideMenu activeConnId={referent}/>
          </Grid>
          <Grid item container xs={12} md={9}>
            <Grid item xs={12} sx={{display: {xs: "block", md: "none"}}}>
              <ConnectionsMobileMenu activeConnection={activeConnection}/>
            </Grid>
            <Grid item xs={12}>
              <ChatView activeConnection={activeConnection}/>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Page>
  )
}

export default BasicMessagesView;