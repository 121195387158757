import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import NewPublicDIDConnection from './NewPublicDIDConnection';
import { ConnectionMethod } from '../../../models/CloudWalletTypes';
import SelectConnectionMethod from './SelectConnectionMethod';
import ConnectionInvitation from './ConnectionInvitation';
import ReceiveInvitation from './ReceiveInvitation';

const NewConnectionView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectView = (connMethod: string|null) => {
    const onSelect = (method: ConnectionMethod) => {
      setSearchParams(oldParams => ({...oldParams, method}))
    }

    switch (connMethod) {
      case ConnectionMethod.SINGLE_USE_INVITATION:
        return <ConnectionInvitation method={ConnectionMethod.SINGLE_USE_INVITATION} />;
      case ConnectionMethod.MULTI_USE_INVITATION:
        return <ConnectionInvitation method={ConnectionMethod.MULTI_USE_INVITATION} />;
      case ConnectionMethod.PUBLIC_DID:
        return <NewPublicDIDConnection />;
      case ConnectionMethod.RECEIVE_INVITATION:
        return <ReceiveInvitation />;
      default:
        return <SelectConnectionMethod onSelect={onSelect} />
    }
  }
  
  return (
    <Page 
      title={t("app.components.NewConnectionView.title")}
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/connections", label: "connections", current: false},
        { href: "/connections/new", label: "new-connection", current: true}
      ]}
    >
      <Container maxWidth="sm">
        { selectView(searchParams.get("method")) }
      </Container>
    </Page>
  );
}

export default NewConnectionView;