import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { FlexColTight, Line, Span } from '../../common/CommonStyles';
import { ConnectionMethod } from '../../../models/CloudWalletTypes';

const SelectConnectionMethod: React.FC<{onSelect: (method: ConnectionMethod) => void}> = ({onSelect}) => {
  const { t } = useTranslation(["translation"]);
  
  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{pb: 2}}>
        {t("app.components.SelectConnectionMethod.helpText")}
      </Typography>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(ConnectionMethod.SINGLE_USE_INVITATION)}>
        {t("app.components.SelectConnectionMethod.useSingleUseInvitationBtn")}
      </Button>
      <Line>
        <Span>{t("common.actions.or.title")}</Span>
      </Line>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(ConnectionMethod.MULTI_USE_INVITATION)}>
        {t("app.components.SelectConnectionMethod.useMultiUseInvitationBtn")}
      </Button>
      <Line>
        <Span>{t("common.actions.or.title")}</Span>
      </Line>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(ConnectionMethod.PUBLIC_DID)}>
        {t("app.components.SelectConnectionMethod.usePublicDIDBtn")}
      </Button>
      <Line>
        <Span>{t("common.actions.or.title")}</Span>
      </Line>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(ConnectionMethod.RECEIVE_INVITATION)}>
        {t("app.components.SelectConnectionMethod.receiveInvitationBtn")}
      </Button>
    </FlexColTight>
  );
}

export default SelectConnectionMethod;