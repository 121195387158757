import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, CircularProgress } from '@mui/material';

const ConnectionStateAlert: React.FC<{state?: string}> = ({state}) => {
  const { t } = useTranslation(["translation"]);
  switch (state) {
    case "active":
      return (
        <Alert severity="success" >
          <AlertTitle>{t("app.components.ConnectionStateAlert.connectionCreatedTitle")}</AlertTitle>
          {t("app.components.ConnectionStateAlert.connectionCreatedMsg")}
        </Alert>
      );
    case "error":
    case "abandoned":
      return (
        <Alert severity="error">
          <AlertTitle>{t("app.components.ConnectionStateAlert.errorTitle")}</AlertTitle>
          {t("app.components.ConnectionStateAlert.connectionErrorMsg")}
        </Alert>
      );
    default:
      return (
        <Alert severity="info" icon={<CircularProgress />} >
          <AlertTitle>{t("app.components.ConnectionStateAlert.connectionPendingTitle")}</AlertTitle>
          {t("app.components.ConnectionStateAlert.connectionPendingMsg")}
        </Alert>
      );
  }
}

export default ConnectionStateAlert;