import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
  titleKey: string,
  contentTextKey: string,
  open: boolean,
  cancelKey?: string,
  cancelBtnColor?: "inherit" | "primary" | "error" | "secondary" | "success" | "info" | "warning",
  confirmKey?: string,
  confirmBtnColor?: "inherit" | "primary" | "error" | "secondary" | "success" | "info" | "warning",
  handleClose: () => void,
  confirmAction: () => void,
  cancelAction: () => void
}

const ConfirmDialog: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        {t(props.titleKey)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(props.contentTextKey)}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button variant="contained" color={props.cancelBtnColor ?? "primary"} onClick={props.cancelAction}>{t(props.cancelKey ?? "common.actions.cancel.title")}</Button>
        <Button variant="contained" color={props.confirmBtnColor ?? "primary"} onClick={props.confirmAction}>{t(props.confirmKey ?? "common.actions.yes.title")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;