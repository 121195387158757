import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText } from '@mui/material';
import { toDateTimeString } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router';
import { ConnRecord } from '../../../models/AcaPyModels';

const ConnectionDetails: React.FC<{connRecord: ConnRecord}> = ({connRecord}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  
  const getConnectionState = () => {
    if (i18n.exists(`app.models.ConnRecordRFC23State.${connRecord.rfc23_state}`)) {
      let color = "info.main";
      if (connRecord.rfc23_state === "completed") {
        color = "success.main"
      }
      return <ListItemText primaryTypographyProps={{ color }} primary={t(`app.models.ConnRecordRFC23State.${connRecord.rfc23_state}`)}/>;
    }
    return <ListItemText primaryTypographyProps={{ color: "error.main"}} primary={t("app.models.ConnRecordRFC23State.error") + ": " + connRecord.rfc23_state}/>;
  }

  return (
    <Grid container spacing={1} sx={{alignItems: "center"}}>
      <Grid item container xs={12} md={6}>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.ConnectionDetails.connectionId")}/>
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={connRecord.connection_id}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.ConnectionDetails.state")}
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={getConnectionState()}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.ConnectionDetails.updatedAt")}
        </Grid>
        <Grid item xs={6}>
          {toDateTimeString(connRecord.updated_at)}
        </Grid>
      </Grid>
      { connRecord.state === "active" && 
        <Grid item container xs={12}>
          <Button variant="contained" onClick={() => navigate(`/connections/${connRecord.connection_id}`)}>
            {t("app.components.ConnectionDetails.openInConnections")}
          </Button>
        </Grid>
      }
    </Grid>
  );
}

export default ConnectionDetails;