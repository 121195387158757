import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';

const CopyToClipboardBtn: React.FC<{textToCopy: string, tooltipKey: string}> = ({textToCopy, tooltipKey}) => {
  const { t } = useTranslation();
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  }
  return (
    <Tooltip disableFocusListener disableTouchListener title={t(tooltipKey)} placement='top-start'>
      <IconButton onClick={() => copyToClipboard(textToCopy)}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
}

export default CopyToClipboardBtn;