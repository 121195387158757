import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText } from '@mui/material';
import { toDateTimeString } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router';
import { V10CredentialExchange } from '../../../models/AcaPyModels';
import { getConnectionLabel, getCredentialId } from '../../../utils/walletUtils';
import { useAppStateContext } from '../../../state/AppStateContext';

const V10CredExchangeDetails: React.FC<{credEx: V10CredentialExchange}> = ({credEx}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  
  const isHolder = () => {
    return credEx.role === V10CredentialExchange.RoleEnum.Holder;
  }

  const getCredential = () => {
    return appContext.credentials?.find(c => getCredentialId(c) === credEx.credential?.referent);
  }

  const getState = () => {
    if (i18n.exists(`app.models.V10CredExRecordState.${credEx.state}`)) {
      let color = "info.main";
      if (credEx.state === "credential_acked") {
        color = "success.main";
      }
      if (credEx.state === "abandoned" || credEx.state === "credential_revoked" || credEx.state === "deleted") {
          color = "error.main";
        }
      return <ListItemText primaryTypographyProps={{ color }} primary={t(`app.models.V10CredExRecordState.${credEx.state}`)}/>;
    }
    return <ListItemText primaryTypographyProps={{ color: "error.main"}} primary={t("app.models.V10CredExRecordState.error") + ": " + credEx.state}/>;
  }

  return (
    <Grid container spacing={1} sx={{alignItems: "center"}}>
      <Grid item container xs={12} md={6}>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.ActivityView.activityId")}/>
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={credEx.credential_exchange_id}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.issuer")}
        </Grid>
        <Grid item xs={6}>
          {isHolder() ? getConnectionLabel(appContext.connections.find( c => c.connection_id === credEx.connection_id)) : appContext.company?.companyName}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.receiver")}
        </Grid>
        <Grid item xs={6}>
        {isHolder() ? appContext.company?.companyName : getConnectionLabel(appContext.connections.find( c => c.connection_id === credEx.connection_id))}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.state")}
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={getState()}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.updatedAt")}
        </Grid>
        <Grid item xs={6}>
          {toDateTimeString(credEx.updated_at)}
        </Grid>
      </Grid>
      { credEx.credential?.referent && getCredential() &&
        <Grid item container xs={12}>
          <Button variant="contained" onClick={() => navigate(`/credential/${credEx.credential?.referent}`)}>
            {t("app.components.CredExchangeDetails.openCredential")}
          </Button>
        </Grid>
      }
      { credEx.credential?.referent && !getCredential() &&
        <Grid item container xs={12}>
          <ListItemText primaryTypographyProps={{variant: "button", color: "error"}} primary={t("app.components.CredExchangeDetails.credentialDeleted")}/>
        </Grid>
      }
    </Grid>
  );
}

export default V10CredExchangeDetails;