import React from 'react';
import { ListItemIcon } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import VerifiedIcon from '@mui/icons-material/Verified';
import QuizIcon from '@mui/icons-material/Quiz';
import { V10CredentialExchange, V10PresentationExchange, V20CredExRecord, V20CredExRecordDetail, V20PresExRecord } from '../../../models/AcaPyModels';
import { BasicMessageWrapper } from '../../../models/CloudWalletTypes';
import { WalletActivity, WalletActivityType } from './ActivityView';


const ActivityIcons: React.FC<{activity: WalletActivity}> = ({activity}) => {

  const getActivityIcons = (activity: WalletActivity) => {
    switch (activity.type) {
      case WalletActivityType.BASIC_MESSAGE:
        const msg = activity.data as BasicMessageWrapper;
        const msgReceiver = msg.message.state === "received";
        return (
          <>
            <MessageIcon color={msgReceiver ? "primary" : "secondary"}/>
            {msgReceiver ? <CallReceivedIcon color="error"/> : <CallMadeIcon color="success"/>}
          </>
        );
      case WalletActivityType.CONNECTION:
        return (<><AccountBoxIcon color="primary"/><FiberNewIcon color="primary"/></>);
      case WalletActivityType.V10_CRED_EXCHANGE:
        const v10cred = activity.data as V10CredentialExchange;
        const v10credReceiver = v10cred.role === V10CredentialExchange.RoleEnum.Holder;
        return (
          <>
            <VerifiedIcon color={v10credReceiver ? "primary" : "secondary"}/>
            {v10credReceiver ? <CallReceivedIcon color="error"/> : <CallMadeIcon color="success"/>}
          </>
        );
      case WalletActivityType.V20_CRED_EXCHANGE:
        const v20cred = activity.data as V20CredExRecordDetail;
        const v20credReceiver = v20cred.cred_ex_record?.role === V20CredExRecord.RoleEnum.Holder;
        return (
          <>
            <VerifiedIcon color={v20credReceiver ? "primary" : "secondary"}/>
            {v20credReceiver ? <CallReceivedIcon color="error"/> : <CallMadeIcon color="success"/>}
          </>
        );
      case WalletActivityType.V10_PROOF_REQUEST:
        const v10proof = activity.data as V10PresentationExchange;
        const v10proofReceiver = v10proof.role === V10PresentationExchange.RoleEnum.Prover;
        return (
          <>
            <QuizIcon color={v10proofReceiver ? "primary" : "secondary"}/>
            {v10proofReceiver ? <CallReceivedIcon color="error"/> : <CallMadeIcon color="success"/>}
          </>
        );
      case WalletActivityType.V20_PROOF_REQUEST:
        const v20proof = activity.data as V20PresExRecord;
        const v20proofReceiver = v20proof.role === V20PresExRecord.RoleEnum.Prover;
        return (
          <>
            <QuizIcon color={v20proofReceiver ? "primary" : "secondary"}/>
            {v20proofReceiver ? <CallReceivedIcon color="error"/> : <CallMadeIcon color="success"/>}
          </>
        );
    }
  }
  
  return (
    <ListItemIcon>
      { getActivityIcons(activity) }
    </ListItemIcon>
  );
}

export default ActivityIcons;