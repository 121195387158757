import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemText } from '@mui/material';
import { V10CredentialExchange, V10PresentationExchange, V20CredExRecord, V20CredExRecordDetail, V20PresExRecord } from '../../../models/AcaPyModels';
import { BasicMessageWrapper } from '../../../models/CloudWalletTypes';
import { WalletActivity, WalletActivityType } from './ActivityView';

const ActivityLabel: React.FC<{activity: WalletActivity}> = ({activity}) => {
  const { t } = useTranslation();

  const getActivityLabel = (activity: WalletActivity): string => {
    switch (activity.type) {
      case WalletActivityType.BASIC_MESSAGE:
        const msg = activity.data as BasicMessageWrapper;
        return msg.message.state === "received" ? "messageReceived" : "messageSent";
      case WalletActivityType.CONNECTION:
        return "newConnection";
      case WalletActivityType.V10_CRED_EXCHANGE:
        const v10cred = activity.data as V10CredentialExchange;
        return v10cred.role === V10CredentialExchange.RoleEnum.Holder ? "credentialReceived" : "credentialIssued";
      case WalletActivityType.V20_CRED_EXCHANGE:
        const v20cred = activity.data as V20CredExRecordDetail;
        return v20cred.cred_ex_record?.role === V20CredExRecord.RoleEnum.Holder ? "credentialReceived" : "credentialIssued";
      case WalletActivityType.V10_PROOF_REQUEST:
        const v10proof = activity.data as V10PresentationExchange;
        return v10proof.role === V10PresentationExchange.RoleEnum.Prover ? "proofRequestReceived" : "proofRequestSent";
      case WalletActivityType.V20_PROOF_REQUEST:
        const v20proof = activity.data as V20PresExRecord;
        return v20proof.role === V20PresExRecord.RoleEnum.Prover ? "proofRequestReceived" : "proofRequestSent";
    }
  }
  
  return (
    <ListItemText primaryTypographyProps={{variant: "button"}} primary={t(`app.components.ActivityLabel.${getActivityLabel(activity)}`)}/>
  );
}

export default ActivityLabel;