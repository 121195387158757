import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as AcaPyTypes from '../models/AcaPyModels';
import { AvailableRecord, BasicMessage, BasicMessageWrapper, CredentialExchangeResponses, CredentialResponse, PingResponse, PresentationResponses, WalletInfo } from '../models/CloudWalletTypes';


const apiRootUrl = process.env.REACT_APP_CLOUD_WALLET_API_URL || 'http://localhost:7072/api/CloudWallet'
const apiKey = process.env.REACT_APP_CLOUD_WALLET_API_KEY || "";
const companyTokenKey = "x-minisuomi-company-token";

const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  const token = sessionStorage.getItem(companyTokenKey);
  if (token && config.headers) {
    config.headers[companyTokenKey] = token;
  }
  return config;
}

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
  withCredentials: true
});

api.interceptors.request.use(requestInterceptor);

export async function GetWalletDIDs(companyID: string): Promise<AxiosResponse<AcaPyTypes. DIDList | null>> {
  return api.get<any>(`/GetWallet`, {params: {companyID}});
}

export async function GetWalletInfo(companyID: string): Promise<AxiosResponse<WalletInfo | null>> {
  return api.get<any>(`/GetWalletInfo`, {params: {companyID}});
}

export async function GetSchema(companyID: string, schemaId: string): Promise<AxiosResponse<AcaPyTypes.SchemaGetResult | null>> {
  return api.get<any>(`/GetSchema`, {params: {companyID, schema_id: schemaId}});
}

export async function GetCredentialDefinition(companyID: string, credDefId: string): Promise<AxiosResponse<AcaPyTypes.CredentialDefinitionGetResult | null>> {
  return api.get<any>(`/GetCredentialDefinition`, {params: {companyID, cred_def_id: credDefId}});
}

export async function GetAvailableSchemas(): Promise<AxiosResponse<AvailableRecord[]>> {
  return api.get<any>(`/GetAvailableSchemas`);
}

export async function GetAvailableCredDefs(): Promise<AxiosResponse<AvailableRecord[]>> {
  return api.get<any>(`/GetAvailableCredDefs`);
}

export async function ListCredentials(companyID: string): Promise<AxiosResponse<AcaPyTypes.CredInfoList | null>> {
  return api.get<any>(`/ListCredentials`, {params: {companyID}});
}

export async function ListCredentialsWithExchange(companyID: string): Promise<AxiosResponse<CredentialResponse[] | null>> {
  return api.get<any>(`/ListCredentialsWithExchange`, {params: {companyID}});
}

export async function ListCredentialExchanges(companyID: string): Promise<AxiosResponse<CredentialExchangeResponses | null>> {
  return api.get<CredentialExchangeResponses>(`/ListCredentialExchanges`, {params: {companyID}});
}

export async function AcceptCredential(walletId: string, credentialId: string): Promise<AxiosResponse<any | null>> {
  return api.post<any>(`/AcceptCredential`, {walletId, credentialId});
} 

export async function DeleteCredential(companyID: string, credentialId?: string, w3cCredentialId?: string, exchangeId?: string, v20credExId?: string): Promise<AxiosResponse<any | null>> {
  return api.post<any>(`/DeleteCredential`, null, {params: {companyID, credentialId, w3cCredentialId, exchangeId, v20credExId}});
}

export async function ListAvailableCredentials(walletId: string, verificationId: string): Promise<AxiosResponse<any | null>> {
  return api.get<any>(`/ListAvailableCredentials`, {params: {walletId, verificationId}});
}

export async function ListConnections(companyID: string): Promise<AxiosResponse<AcaPyTypes.ConnectionList | null>> {
  return api.get<AcaPyTypes.ConnectionList>(`/ListConnections`, {params: {companyID}});
}

export async function GetConnection(companyID: string, connectionId: string): Promise<AxiosResponse<AcaPyTypes.ConnRecord | null>> {
  return api.get<AcaPyTypes.ConnRecord>(`/GetConnection`, {params: {companyID, connectionId}});
}

export async function CreateInvitation(companyID: string, alias?: string, multiUse?: boolean): Promise<AxiosResponse<AcaPyTypes.InvitationResult | null>> {
  return api.post<AcaPyTypes.InvitationResult>(`/CreateInvitation`, null, {params: {companyID, alias, "multi-use": multiUse}});
}

export async function AcceptConnection(companyID: string, inviteUrl: string): Promise<AxiosResponse<AcaPyTypes.ConnRecord | null>> {
  return api.post<AcaPyTypes.ConnRecord>(`/ReceiveConnection`, null, {params: {companyID, inviteUrl}});
}

export async function GetCreatePublicDidConnection(companyID: string, did: string, alias?: string): Promise<AxiosResponse<AcaPyTypes.ConnRecord | null>> {
  return api.post<AcaPyTypes.ConnRecord>(`/GetCreatePublicDidConnection`, null, {params: {companyID, did, alias}});
}

export async function DeleteConnection(companyID: string, connectionId: string): Promise<AxiosResponse<any | null>> {
  return api.post<any>(`/DeleteConnection`, null, {params: {companyID, connectionId }});
}

export async function PingConnections(connectionIds: string[]): Promise<AxiosResponse<PingResponse[] | null>> {
  return api.post<PingResponse[]>(`/PingConnection`, connectionIds);
}

export async function ListPresentationExchanges(): Promise<AxiosResponse<PresentationResponses | null>> {
  return api.get<PresentationResponses>(`/ListPresentationExchanges`);
}

export async function GetV10PresentationExchange(presExId: string): Promise<AxiosResponse<AcaPyTypes.V10PresentationExchange | null>> {
  return api.get<AcaPyTypes.V10PresentationExchange>(`/GetV10PresentationExchange`, {params: {presExId}});
}

export async function ListAvailableCredentialsForProofRequest(companyID: string, presExId: string): Promise<AxiosResponse<AcaPyTypes.IndyCredPrecis[] | null>> {
  return api.get<AcaPyTypes.IndyCredPrecis[]>(`/ListAvailableCredentialsForProofRequest`, {params: {companyID, presExId}});
}

export async function SendPresentation(presExId: string, presentation: AcaPyTypes.IndyPresSpec): Promise<AxiosResponse<AcaPyTypes.V10PresentationExchange | null>> {
  return api.post<AcaPyTypes.V10PresentationExchange>(`/SendPresentation`, {...presentation}, {params: {presExId}});
}

export async function RejectProofRequest(presExId: string, problemReport: AcaPyTypes.V10PresentationProblemReportRequest): Promise<AxiosResponse<AcaPyTypes.V10PresentProofModuleResponse | null>> {
  return api.post<AcaPyTypes.V10PresentProofModuleResponse>(`/RejectProofRequest`, {...problemReport}, {params: {presExId}});
}

export async function SendProofRequest(proofRequest: AcaPyTypes.V10PresentationSendRequestRequest): Promise<AxiosResponse<AcaPyTypes.V10PresentationExchange>> {
  return api.post<AcaPyTypes.V10PresentationExchange>(`/SendProofRequest`, proofRequest);
}

export async function VerifyPresentation(presExId: string): Promise<AxiosResponse<AcaPyTypes.V10PresentationExchange>> {
  return api.post<AcaPyTypes.V10PresentationExchange>(`/VerifyPresentation`, null, {params: {presExId}});
}

export async function SubmitVerification(walletId: string, verificationId: string): Promise<AxiosResponse<void>> {
  return api.post(`/SubmitVerification`, null, {params: {walletId, verificationId}});
}

export async function SubmitVerificationFromData(walletId: string, verificationData: string): Promise<AxiosResponse<void>> {
  return api.post(`/SubmitVerificationFromData`, {walletId, verificationData});
}

export async function GetBasicMessages(companyID: string): Promise<AxiosResponse<BasicMessageWrapper[] | null>> {
  return api.get<BasicMessageWrapper[]>(`/GetBasicMessages`, {params: {companyID}});
}

export async function SendBasicMessage(companyID: string, message: BasicMessage): Promise<AxiosResponse<void>> {
  return api.post(`/SendBasicMessage`, message, {params: { companyID }});
}

export async function IssueCredential(companyID: string, connectionId: string, schemaId?: string): Promise<AxiosResponse<AcaPyTypes.V20CredExRecord | null>> {
  return api.post<AcaPyTypes.V20CredExRecord>(`/IssueCredential`, null, {params: {companyID, connectionId, schemaId}});
}
