import React from 'react';
import { ListItemText } from '@mui/material';
import { getUpdatedAt } from '../../../utils/walletUtils';
import { WalletActivity } from './ActivityView';
import { toDateTimeString } from '../../../utils/dateUtils';

const ActivityTimestamp: React.FC<{activity: WalletActivity}> = ({activity}) => {

  const getTimestamp = (activity: WalletActivity) => {
    const updatedAt = getUpdatedAt(activity);
    return updatedAt ? toDateTimeString(updatedAt) : "";
  }
  
  return (
    <ListItemText primary={getTimestamp(activity)}/>
  );
}

export default ActivityTimestamp;