/** @jsxImportSource @emotion/react */
import React, { Suspense } from 'react';
import { css, Global } from '@emotion/react';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { ContentContainer, HeaderBackground, TopNavigationBackground } from './components/common/CommonStyles';
import Header from './components/common/Header';
import Home from './components/pages/Home';
import AppContextProvider, { AppStateContext } from './state/AppStateContext';
import ConnectionsView from './components/pages/Connections/ConnectionsView';
import LoginView from './components/pages/LoginView';
import NavBar from './components/common/NavBar';
import CredentialsView from './components/pages/CredentialsView';
import BasicMessagesView from './components/pages/BasicMessages/BasicMessages';
import PageNotFoundView from './components/pages/PageNotFoundView';
import CredentialView from './components/pages/CredentialView';
import NewConnectionView from './components/pages/Connections/NewConnectionView';
import ActivityView from './components/pages/Activity/ActivityView';
import PresentationRequestView from './components/pages/Presentations/PresentationRequestView';
import PresentationsView from './components/pages/Presentations/PresentationsView';
import NewPresentationRequestView from './components/pages/Presentations/NewPresentationRequestView';

const App: React.FC = () => {

  return (
    <Suspense fallback="loading">
      <AppContextProvider>
        <Global styles={css`
          body {
            background-color: ${tokens.colors.depthLight3};
            font-family: ${tokens.values.typography.bodyText.fontFamily};
            font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: ${tokens.values.typography.bodyText.lineHeight.value}${tokens.values.typography.bodyText.lineHeight.unit};
            margin: 0;
          }
          a {
            color: ${tokens.colors.highlightBase};
            text-decoration: none; 
          }
          a:visited {
            color: ${tokens.colors.accentTertiaryDark1}
          }
          code {
            white-space: pre-wrap !important;
          }
          label {
            font-weight: ${tokens.values.typography.bodySemiBold.fontWeight}
          } 
          hr {
            border: 0;
            border-bottom: 1px solid ${tokens.colors.depthLight3};
            margin: ${tokens.spacing.m} 0;
          }
          ul, ol {
            margin: 0;
            padding: 0;
            @media (max-width: 1200px) {
              list-style-position: inside;              
            }
          }
          li {
            margin: 0;
            padding: 0;            
          }
          p {
            margin: ${tokens.spacing.m} 0;
          }
        `}
        />
        <Router>
          <HeaderBackground>
            <ContentContainer>
              <Header/>
              {/* <div><b style={{color:"red"}}>WE ARE UPDATING SYSTEM!</b></div> */}
            </ContentContainer>
          </HeaderBackground>

          <TopNavigationBackground>
            <ContentContainer>
              <NavBar/>
            </ContentContainer>
          </TopNavigationBackground>

          <AppStateContext.Consumer>
            {state => !state.isLoadingApp && !state.company ?
              <Routes>
                <Route path="/login" element={<LoginView/>}/>
                <Route path="*" element={<Navigate to={"/login"}/>}/>
              </Routes> :
              <Routes>
                { RoutePaths.map((it, i) => 
                  <Route key={`route-${i}`} path={it.to} element={it.element}/>
                )}
                <Route path="/login" element={<Navigate to={"/"}/>}/>
                <Route path="/credential/:referent" element={<CredentialView/>}/>
                <Route path="/404" element={<PageNotFoundView/>}/>
                <Route path="*" element={<Navigate to={"/404"}/>}/>
              </Routes>
            }
          </AppStateContext.Consumer>
        </Router>
      </AppContextProvider>
    </Suspense>
  )
};

export interface RoutePathProps {
  to: string;
  label: string;
  element: React.ReactNode | null;
  visibleInNav: boolean;
}

export const RoutePaths: RoutePathProps[] = [
  { to: "/", label: "frontpage", element: <Home/>, visibleInNav: true},
  { to: "/connections", label: "connections", element: <ConnectionsView/>, visibleInNav: true},
  { to: "/connections/new", label: "new-connection", element: <NewConnectionView/>, visibleInNav: false},
  { to: "/messages", label: "messages", element: <BasicMessagesView/>, visibleInNav: true},
  { to: "/messages/:referent", label: "messages", element: <BasicMessagesView/>, visibleInNav: false},
  { to: "/credentials", label: "credentials", element: <CredentialsView/>, visibleInNav: true},
  { to: "/credential/:referent", label: "credential", element: <CredentialView/>, visibleInNav: false},
  { to: "/presentations", label: "presentations", element: <PresentationsView/>, visibleInNav: true},
  { to: "/presentation/:referent", label: "presentation", element: <PresentationRequestView/>, visibleInNav: false},
  { to: "/presentations/new", label: "new-presentation", element: <NewPresentationRequestView/>, visibleInNav: false},
  { to: "/activity", label: "activity", element: <ActivityView/>, visibleInNav: true},
]

export default App;