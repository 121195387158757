import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText } from '@mui/material';
import { toDateTimeString } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router';
import { V20PresExRecord } from '../../../models/AcaPyModels';
import { getConnectionLabel } from '../../../utils/walletUtils';
import { useAppStateContext } from '../../../state/AppStateContext';

const V20PresExchangeDetails: React.FC<{presEx: V20PresExRecord}> = ({presEx}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  
  const isProver = () => {
    return presEx.role === V20PresExRecord.RoleEnum.Prover;
  }

  const getState = () => {
    if (i18n.exists(`app.models.V20PresExRecordState.${presEx.state}`)) {
      let color = "info.main";
      if (presEx.state === V20PresExRecord.StateEnum.Done) {
        color = "success.main";
      }
      if (presEx.state === V20PresExRecord.StateEnum.Abandoned) {
          color = "error.main";
        }
      return <ListItemText primaryTypographyProps={{ color }} primary={t(`app.models.V20PresExRecordState.${presEx.state}`)}/>;
    }
    return <ListItemText primaryTypographyProps={{ color: "error.main"}} primary={t("app.models.V20PresExRecordState.error") + ": " + presEx.state}/>;
  }

  return (
    <Grid container spacing={1} sx={{alignItems: "center"}}>
      <Grid item container xs={12} md={6}>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.ActivityView.activityId")}/>
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={presEx.pres_ex_id}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.comment")}
        </Grid>
        <Grid item xs={6}>
          {presEx.pres_request?.comment}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.verifier")}
        </Grid>
        <Grid item xs={6}>
          {isProver() ? getConnectionLabel(appContext.connections.find( c => c.connection_id === presEx.connection_id)) : appContext.company?.companyName}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.prover")}
        </Grid>
        <Grid item xs={6}>
        {isProver() ? appContext.company?.companyName : getConnectionLabel(appContext.connections.find( c => c.connection_id === presEx.connection_id))}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.state")}
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={getState()}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.PresExchangeDetails.updatedAt")}
        </Grid>
        <Grid item xs={6}>
          {toDateTimeString(presEx.updated_at)}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Button variant="contained" onClick={() => navigate(`/presentation/${presEx.pres_ex_id}`)}>
          {t("app.components.CredExchangeDetails.openCredential")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default V20PresExchangeDetails;