import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { ConnRecord } from '../../models/AcaPyModels';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { getConnectionLabel } from '../../utils/walletUtils';

interface Props {
  connection?: ConnRecord,
  handleClose: () => void,
  confirmAction: () => void,
  cancelAction: () => void
}

const DeleteConnectionDialog: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={props.connection !== undefined} onClose={props.handleClose}>
      <DialogTitle>
        {t("app.components.DeleteConnectionDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box sx={{marginBottom: tokens.spacing.xs}}>
          <Typography variant="button">
            {getConnectionLabel(props.connection)}
          </Typography>
        </Box>
        <DialogContentText>
          {t("app.components.DeleteConnectionDialog.contentText")}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button variant="contained" onClick={props.cancelAction}>{t("common.actions.cancel.title")}</Button>
        <Button variant="contained" color="error" onClick={props.confirmAction}>{t("common.actions.delete.title")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConnectionDialog;