import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { useAppStateContext } from '../../state/AppStateContext';
import { toDateTimeString } from '../../utils/dateUtils';
import Page from '../common/Page';
import { getConnectionLabel, getCredentialType } from '../../utils/walletUtils';
import { AppContextCredential, CredentialType } from '../../models/CloudWalletTypes';
import { IndyCredInfo, V10CredentialExchange, V20CredExRecordDetail, VCRecord } from '../../models/AcaPyModels';
import { WC3VerifiableCredential } from '../../models/WaltidModels';

const CredentialsView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    appContext.getConnectionsAsync();
    appContext.getCredentialsAsync();
    appContext.getWaltidCredentials();
  }, []);
  
  const refresh = () => {
    setLoading(() => true);
    appContext.refreshWalletState()
    .then(() => setLoading(() => false));
  }

  const getIssuerLabel = (cred: AppContextCredential) => {
    let connectionId: string|undefined = undefined;
    if (cred.type === CredentialType.INDY_CREDENTIAL_V1) {
      const _exchange = cred.exchange as V10CredentialExchange;
      connectionId = _exchange?.connection_id;
    }
    else if (cred.type === CredentialType.INDY_CREDENTIAL_V2 || cred.type === CredentialType.ACAPY_W3C_CREDENTIAL) {
      const _exchange = cred.exchange as V20CredExRecordDetail;
      connectionId = _exchange?.cred_ex_record?.connection_id;
    }
    if (!connectionId) {
      return "";
    }
    const conn = appContext.connections.find(c => c.connection_id === connectionId);
    return conn ? getConnectionLabel(conn) : "";
  }

  const navigateToCredential = (referent: string) => {
    navigate("/credential/" + referent);
  }

  return (
    <Page 
      title={t("app.components.CredentialsView.title")}
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/credentials", label: "credentials", current: true}
      ]}
      titleAction={
        <Button 
          startIcon={loading ? <HourglassEmptyIcon/> : <RefreshIcon/>}
          onClick={refresh}
        >
          {loading ? t("common.actions.refreshing.title") : t("common.actions.refresh.title")}
        </Button>
      }
    >
      <Paper elevation={3}>
        <List disablePadding>
        { appContext.credentials?.map((item, index, array) =>
          <ListItem 
          key={`credentials-${index}`}
          divider={index < array.length - 1}
          >
            <Grid container style={{alignItems: "center"}}>
              <Grid item xs={6} sm={4}>
                <ListItemText primary={getCredentialType(item)} secondary={toDateTimeString(item.createdAt)}/>
              </Grid>
              <Grid item xs={6} sm={3}>
                <ListItemText primary={t(`app.models.CredentialType.${item.type}`)}/>
              </Grid>
              <Grid item xs={10} sm={4}>
                <ListItemText primary={getIssuerLabel(item)}/>
              </Grid>
              <Grid item xs={2} sm={1} style={{display: "flex", justifyContent: "right"}}>
                <IconButton 
                  color="primary" 
                  sx={{"backgroundColor": tokens.colors.highlightLight2}}
                  onClick={() => {
                    if (item.type === CredentialType.ACAPY_W3C_CREDENTIAL) {
                      const _cred = item.credential as VCRecord;
                      navigateToCredential(_cred.record_id!)
                      
                    }
                    else if (item.type === CredentialType.WALTID_W3C_CREDENTIAL) {
                      const _cred = item.credential as WC3VerifiableCredential;
                      navigateToCredential(_cred.id.replace("urn:uuid:", ""));
                    }
                    else {
                      const _cred = item.credential as IndyCredInfo;
                      navigateToCredential(_cred.referent!)
                    }
                  }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        )}
        </List>
      </Paper>
    </Page>
  )
}

export default CredentialsView;