/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState } from 'react';
import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../../state/AppStateContext';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ConnRecord } from '../../../models/AcaPyModels';
import { BasicMessage } from '../../../models/CloudWalletTypes';
import { SendBasicMessage } from '../../../api/CloudWalletApi';
import { getConnectionLabel } from '../../../utils/walletUtils';

const ChatView: React.FC<{activeConnection?: ConnRecord}> = ({activeConnection}) => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [message, setMessage] = useState<string>("");
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);

  const styles = {
    paper: css({
      display: "flex",
      flexDirection: "column",
      minHeight: "40vh",
      maxHeight: "60vh",
      padding: tokens.spacing.xs,
      backgroundColor: "#F9FAFB"
    }),
    messagesBody: css({
      margin: tokens.spacing.xs,
      flexGrow: 1,
      display: "flex",
      flexDirection: "column-reverse",
      overflow: "auto"
    }),
    messageContainer: css({
      width: "100%",
      height: "100%",
    }),
    inputBox: css({
      margin: tokens.spacing.xs,
      display: "flex"
    })
  }

  const sendBasicMessage = async (content: string): Promise<void> => {
    if (activeConnection?.connection_id && appContext.company?.id) {
      const basicMessage: BasicMessage = {
        connection_id: activeConnection.connection_id,
        message_id: "",
        content: content,
        state: "",
        sent_time: ""
      }
      SendBasicMessage(appContext.company.id, basicMessage)
      .then(res => {
       if (res.status === 200) {
        // Reload messages
        appContext.getBasicMessagesAsync();
        setMessage("");
       }
      })
      .catch(err => console.log("Error while sending a message", err));
    }
  }

  if (!activeConnection) {
    return (
      <Paper elevation={3} css={styles.paper}>
        <Typography variant="button" sx={{margin: "auto"}}>{t("app.components.BasicMessagesView.selectConnection")}</Typography>
      </Paper>
    );
  }
  
  return (
    <Paper elevation={3} css={styles.paper}>
      <div css={styles.messagesBody}>
        { appContext.messages[activeConnection.connection_id!] && appContext.messages[activeConnection.connection_id!].map((msg, msgIndex) => (
          <Box 
            key={"msg-" + msgIndex} 
            sx={{
              display: "flex", 
              justifyContent: msg.message.state === "received" ? "normal" : "flex-end"
            }}
          >
            <Paper 
              elevation={3} 
              sx={{
                display: "flex", 
                maxWidth: "80%", 
                padding: tokens.spacing.xxs, 
                margin: tokens.spacing.xxs,
                backgroundColor: msg.message.state === "received" ? tokens.colors.whiteBase : tokens.colors.highlightLight3
              }}
            >
              <Box>
                <div>
                  { msg.message.state === "received" &&
                    <Typography variant="caption" fontWeight={700} sx={{marginRight: tokens.spacing.xxs}}>
                      {getConnectionLabel(activeConnection)}
                    </Typography>
                  }
                  <Typography variant="caption">
                    {(new Date(msg.message.sent_time)).toLocaleString()}
                  </Typography>
                </div>
                <div style={{whiteSpace: "pre-line"}}>
                  {msg.message.content}
                </div>
              </Box>
            </Paper>
          </Box>
        ))}
      </div>
      <Box css={styles.inputBox}>
        <TextField 
          variant="outlined" 
          multiline
          sx={{flexGrow: 1, mr: tokens.spacing.xs}}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <Button 
          variant="contained" 
          disabled={sendingMessage || message?.length < 3} 
          onClick={() => sendBasicMessage(message)} 
          endIcon={sendingMessage ? <CircularProgress size={20}/> : <SendIcon />}
          sx={{display: {xs: "none", sm: "inherit"}}}
        >
          {t("app.components.BasicMessagesView.send")}
        </Button>
        <Button 
          variant="contained" 
          disabled={sendingMessage || message?.length < 3} 
          onClick={() => sendBasicMessage(message)} 
          sx={{display: {xs: "inherit", sm: "none"}}}
        >
          {sendingMessage ? <CircularProgress size={20}/> : <SendIcon />}
        </Button>
      </Box>
    </Paper>
  );
}

export default ChatView;