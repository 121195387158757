/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../state/AppStateContext';
import { Box, Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { AppContextCredential, CredentialType } from '../../models/CloudWalletTypes';
import Page from '../common/Page';
import ConfirmDialog from '../common/ConfirmDialog';
import LoadingBackdrop from '../common/Backdrop';
import { DeleteCredential } from '../../api/CloudWalletApi';
import * as WaltidWalletApi from "../../api/WaltidWalletApi";
import { getCredentiaExchangelId, getCredentialId, getCredentialType } from '../../utils/walletUtils';
import { IndyCredInfo, VCRecord } from '../../models/AcaPyModels';
import { WC3VerifiableCredential } from '../../models/WaltidModels';
import IndyCredentialView from './Credentials/IndyCredentialView';
import W3CCredentialView from './Credentials/W3CCredentialView';

export const CredCell = styled.div`
  padding: 6px 24px 6px 16px;
  display: flex;
`

export const CredCellRight = styled.div`
  padding: 6px 24px 6px 16px;
  display: flex;
  justify-content: flex-end;
`

export const CredCellContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  word-break:break-all;
`

const CredentialView: React.FC = () => {
  const navigate = useNavigate();
  const { referent } = useParams();
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [cred, setCred] = useState<AppContextCredential|undefined>(undefined);
  const [visualize, setVisualize] = useState<boolean>(true);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [backdrop, setBackdrop] = useState<boolean>(false);

  useEffect(() => {
    if (appContext.credentials) {
      const matchingCred = appContext.credentials.find(it => {
        if (it.type === CredentialType.ACAPY_W3C_CREDENTIAL) {
          const _cred = it.credential as VCRecord;
          console.log(`CredentialView VCRecord referent: ${referent}, cred id: ${_cred.record_id}`)
          return _cred.record_id === referent;
        }
        else if (it.type === CredentialType.WALTID_W3C_CREDENTIAL) {
          const _cred = it.credential as WC3VerifiableCredential;
          console.log(`CredentialView WC3VerifiableCredential referent: ${referent}, cred id: ${_cred.id}`)
          return _cred.id === `urn:uuid:${referent}`;
        }
        const _cred = it.credential as IndyCredInfo;
        console.log(`CredentialView IndyCredInfo referent: ${referent}, cred id: ${_cred.referent}`)
        return _cred.referent === referent;
      });
      console.log("matchingCred", matchingCred);
      if (!matchingCred) {
        navigate("/404");
      }
      else {
        setCred(matchingCred);
      }
    }
  }, [appContext.credentials]);

  const deleteCredential = (companyID: string, cred: AppContextCredential) => {
    const _credId = getCredentialId(cred);
    const _exchangeId = getCredentiaExchangelId(cred);
    if (cred.type == CredentialType.INDY_CREDENTIAL_V1) {
      deleteAcaPyCredential(companyID, _credId, undefined, _exchangeId, undefined);
    }
    else if (cred.type == CredentialType.INDY_CREDENTIAL_V2) {
      deleteAcaPyCredential(companyID, undefined, _credId, undefined, _exchangeId);
    }
    else if (cred.type == CredentialType.WALTID_W3C_CREDENTIAL && _credId) {
      deleteWaltidCredential(_credId);
    }
  }

  const deleteAcaPyCredential = (companyID: string, credId?: string, w3cCredId?: string, v10ExchangeId?: string, v20ExchangeId?: string) => {
    setBackdrop(() => true);
    DeleteCredential(companyID, credId, w3cCredId, v10ExchangeId, v20ExchangeId)
    .then(() => {
      appContext.getCredentialsAsync();
      setBackdrop(() => false);
      navigate("/credentials");
    })
    .catch(err => {
      console.log("Error when deleting a credential", err);
      setBackdrop(() => false);
    });
  }

  const deleteWaltidCredential = (credId: string) => {
    setBackdrop(() => true);
    WaltidWalletApi.DeleteCredential(credId)
    .then(() => {
      appContext.getWaltidCredentials();
      setBackdrop(() => false);
      navigate("/credentials");
    })
    .catch(err => {
      console.log("Error when deleting a credential", err);
      setBackdrop(() => false);
    });
  }

  return cred ? (
    <Page 
    title={getCredentialType(cred)}
    breadcrumbs={[
      { href: "/", label: "frontpage", current: false},
      { href: "/credentials", label: "credentials", current: false},
      { href: "/credential/:referent", label: "credential", current: true}
    ]}
    titleAction={
      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("app.components.CredentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>
    }
    >
      <div>
        { (cred.type === CredentialType.INDY_CREDENTIAL_V1 || cred.type === CredentialType.INDY_CREDENTIAL_V2) &&
          <IndyCredentialView credential={cred.credential as IndyCredInfo} visualize={visualize} />
        }
        { cred.type === CredentialType.ACAPY_W3C_CREDENTIAL &&
          <W3CCredentialView credential={cred.credential as VCRecord} visualize={visualize}/>
        }
        { cred.type === CredentialType.WALTID_W3C_CREDENTIAL &&
          <W3CCredentialView credential={cred.credential as WC3VerifiableCredential} visualize={visualize}/>
        }
        { appContext.company?.id && 
          <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: tokens.spacing.s}}>
            <Button variant="contained" color="error" onClick={() => setOpenConfirmDelete(() => true)}>
              {t("common.actions.delete.title")}
            </Button>
            <ConfirmDialog 
              titleKey="app.components.CredentialView.confirmDeleteTitle" 
              contentTextKey="app.components.CredentialView.confirmDeleteContentText"
              open={openConfirmDelete}
              confirmKey="common.actions.delete.title"
              confirmBtnColor="error"
              handleClose={() => setOpenConfirmDelete(() => false)}
              confirmAction={() => deleteCredential(appContext.company!.id, cred)}
              cancelAction={() => setOpenConfirmDelete(() => false)}
            />
            <LoadingBackdrop open={backdrop}/>
          </Box>
        }
      </div>
    </Page>
  ) :
  (<></>);
}

export default CredentialView;