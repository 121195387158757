import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../../state/AppStateContext';
import { Button, TextField, Typography } from '@mui/material';
import { FlexColTight } from '../../common/CommonStyles';
import { ConnRecord } from '../../../models/AcaPyModels';
import ConnectionStateAlert from './ConnectionStateAlert';
import useInterval from '../../../utils/useInterval';

interface ConnectionStatus {
  error: boolean,
  messageKey: string
}

const ReceiveInvitation: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [connectionUrl, setConnectionUrl] = useState("");
  const [connection, setConnection] = useState<ConnRecord|undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    error: false,
    messageKey: "app.components.ReceiveInvitation.creatingConnection"
  });

  useInterval(async () => {
    if (connection && connection.state !== "active") {
      appContext.getConnectionsAsync()
      .then(connections => {
        const match = connections.find(c => c.connection_id === connection.connection_id);
        if (match) {
          setConnection(() => match);
          if (match.state === "active" || match.state === "abandoned" || match.state === "error") {
            setRefresh(() => false);
          }
        }
      });
    }
  }, refresh ? 3000 : null);

  const onConfirm = () => {
    appContext.acceptConnectionAsync(connectionUrl)
    .then(connRecord => {
      if (connRecord) {
        setConnection(() => connRecord);
        setRefresh(() => true);
      }
      else {
        setConnection(() => undefined);
      }
    })
    .catch(err => {
      setConnectionStatus(() => ({
        error: true,
        messageKey: "app.components.ReceiveInvitation.connectionUrlError"
      }));
    });
  }

  return connection?.state ? 
  <ConnectionStateAlert state={connection.state}/> :
  <FlexColTight>
    <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 2}}>
      {t("app.components.ReceiveInvitation.helpText")}
    </Typography>
    <TextField
      value={connectionUrl}
      error={connectionStatus.error}
      helperText={connectionStatus.error && t(connectionStatus.messageKey)}
      onChange={(e) => setConnectionUrl(e.target.value)}
    />
    <Button
      variant="contained"
      onClick={() => onConfirm()}
      sx={{ mt: 1 }}
    >{t("common.actions.send.title")}</Button>
  </FlexColTight>;
}

export default ReceiveInvitation;