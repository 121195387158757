import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import { toDateTimeString } from '../../../utils/dateUtils';
import LoadingBackdrop from '../../common/Backdrop';
import DeleteConnectionDialog from '../../modals/DeleteConnectionDialog';
import { ConnRecord } from '../../../models/AcaPyModels';
import { DeleteConnection, IssueCredential } from '../../../api/CloudWalletApi';
import { useNavigate } from 'react-router';
import { getConnectionLabel } from '../../../utils/walletUtils';

const ConnectionsView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const [connectionToDelete, setConnectionToDelete] = useState<ConnRecord|undefined>(undefined);
  const [backdrop, setBackdrop] = useState<boolean>(false);

  useEffect(() => {
    console.log("ConnectionsView useEffect, getConnectionsAsync");
    appContext.getConnectionsAsync()
    .then(connRecords => {
      console.log("ConnectionsView useEffect, connRecords", connRecords);
      return appContext.pingConnectionsAsync(connRecords.filter(c => c.state === "active"));
    });
  }, []);

  const deleteConnection = (connection?: ConnRecord) => {
    if (appContext.company && connection?.connection_id) {
      setBackdrop(() => true);
      DeleteConnection(appContext.company.id, connection.connection_id!)
      .then(() => {
        setConnectionToDelete(() => undefined);
        appContext.getConnectionsAsync();
        setBackdrop(() => false);
      })
      .catch(err => {
        console.log("Error when deleting a connection", err);
        setBackdrop(() => false);
      });
    }
  }

  const IssueV20DemotestCredential = (connection?: ConnRecord) => {
    if (appContext.company && connection?.connection_id) {
      setBackdrop(() => true);
      IssueCredential(appContext.company.id, connection.connection_id!, "7TpeGhjLmGh7mj4Zyvmcxt:2:DemoTest:1.0")
      .then(res => {
        console.log("IssueV20DemotestCredential response", res);
        setBackdrop(() => false);
      })
      .catch(err => {
        console.log("Error when issuing a credential", err);
        setBackdrop(() => false);
      });
    }
  }

  const getConnectionPingStatus = (connection?: ConnRecord) => {
    const pingPesponse = appContext.connectionPings[connection?.connection_id!];
    if (pingPesponse) {
      return "success";
    }
    if (pingPesponse === null) {
      return "error";
    }
    return "disabled";
  }

  const getConnectionPingStatusContent = (connection?: ConnRecord) => {
    const pingPesponse = appContext.connectionPings[connection?.connection_id!];
    if (pingPesponse) {
      return t("app.components.ConnectionsView.connectionActive");
    }
    if (pingPesponse === null) {
      return t("app.components.ConnectionsView.connectionNoResponse");
    }
    return t("app.components.ConnectionsView.connectionUnkown");
  }

  return (
    <Page 
      title={t("app.components.ConnectionsView.title")}
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/connections", label: "connections", current: true}
      ]}
      titleAction={
        <Button 
          endIcon={ <AddCircleOutlineIcon/>}
          onClick={() => navigate("/connections/new")}
        >
          {t("app.components.ConnectionsView.newConnectionBtn")}
        </Button>
      }
    >
      <div>
      { appContext.getActiveConnections().map((connRecord, index) => 
        <Accordion elevation={3} disableGutters key={`connection-${index}-${connRecord.connection_id}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container style={{alignItems: "center"}}>
              <Grid item style={{display: "flex", alignItems: "center", paddingRight: tokens.spacing.s}} xs="auto">
                <CircleIcon color={getConnectionPingStatus(connRecord)} fontSize="small"/>
              </Grid>
              <Grid item xs>
                <ListItemText primary={getConnectionLabel(connRecord)}/>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  {t("app.components.ConnectionsView.createdAt")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {toDateTimeString(connRecord.created_at)}
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  {t("app.components.ConnectionsView.connectionPingStatus")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getConnectionPingStatusContent(connRecord)}
                </Grid>
              </Grid>
              { connRecord.their_public_did &&
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={6}>
                    {t("app.components.ConnectionsView.publicDID")}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {connRecord.their_public_did}
                  </Grid>
                </Grid>
              }
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  {t("app.components.ConnectionsView.issueV20DemoTestCredential")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" onClick={() => IssueV20DemotestCredential(connRecord)}>
                    {t("common.actions.send.title")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Button color="error" onClick={() => setConnectionToDelete(() => connRecord)}>
                  {t("app.components.ConnectionsView.deleteConnectionBtn")}
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      <DeleteConnectionDialog
        connection={connectionToDelete}
        handleClose={() => setConnectionToDelete(() => undefined)}
        cancelAction={() => setConnectionToDelete(() => undefined)}
        confirmAction={() => deleteConnection(connectionToDelete)}
      />
      <LoadingBackdrop open={backdrop}/>
      </div>
    </Page>
  )
}

export default ConnectionsView;