import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, Box, CircularProgress, FormControl, FormHelperText, Grid, IconButton, ListItemText, Paper, TextField, createFilterOptions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from "../../../state/AppStateContext";
import { AvailableRecord } from "../../../models/CloudWalletTypes";
import { debounce, isEqual } from "lodash";

interface Props {
  onSubmit: (key: string, value: string) => void
  onCancel: () => void
}

interface RestrictionOption {
  key: string,
  value: string
}

const restrictions: RestrictionOption[] = [
  // {
  //   key: "restriction-empty",
  //   value: "restrictionEmpty"
  // },
  {
    key: "restriction-schemaId",
    value: "restrictionSchemaId"
  },
  {
    key: "restriction-credDefId",
    value: "restrictionCredDefId"
  },
  // {
  //   key: "restriction-issuerDID",
  //   value: "restrictionIssuerDid"
  // }
]

interface AvailableRecordOption extends AvailableRecord {
  title?: string
}

const AddProofRestrictionForm: React.FC<Props> = ({onSubmit, onCancel}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [valueLabelKey, setValueLabelKey] = useState<string>("selectRestriction");
  const [loading, setLoading] = useState<boolean>(false);
  const unqualifiedSchemaIdRegex = /^([a-zA-Z0-9]{21,22}):2:(.+):([0-9.]+)$/;
  const unqualifiedCredentialDefinitionIdRegex = /^([a-zA-Z0-9]{21,22}):3:CL:([1-9][0-9]*):(.+)$/;
  const unqualifiedIndyDidRegex = /^(did:sov:)?[a-zA-Z0-9]{21,22}$/;
  const [availableSchemas, setAvailableSchemas] = useState<AvailableRecordOption[]>([]);
  const [availableCredDefs, setAvailableCredDefs] = useState<AvailableRecordOption[]>([]);

  useEffect(() => {
    appContext.getAvailableSchemasAsync().then(res => setAvailableSchemas(() => res || []));
    appContext.getAvailableCredDefsAsync().then(res => setAvailableCredDefs(() => res || []));
  }, []);

  const getOptions = (restrictionType: string) => {
    switch(restrictionType) {
      case "restrictionSchemaId":
        return availableSchemas;
      case "restrictionCredDefId":
        return availableCredDefs;
      case "restrictionIssuerDid":
        return [];
      default:
        return undefined;
    }
  }

  const addRestriction = () => {
    setLoading(() => true);
    if (formik.values.restriction === "restrictionSchemaId") {
      appContext.getSchemaAsync(formik.values.value)
      .then(schema => {
        if (schema) {
          onSubmit("schema_id", formik.values.value);
        }
        else {
          formik.setFieldError("value", "SchemaNotFoundError");
        }
      })
      .catch(err => {
        console.error("Error while fetching schema", err);
      })
      .finally(() => setLoading(() => false));
    }
    if (formik.values.restriction === "restrictionCredDefId") {
      appContext.getCredentialDefinitionAsync(formik.values.value)
      .then(credDef => {
        if (credDef) {
          onSubmit("cred_def_id", formik.values.value);
        }
        else {
          formik.setFieldError("value", "CredDefNotFoundError");
        }
      })
      .catch(err => {
        console.error("Error while fetching credential definition", err);
      })
      .finally(() => setLoading(() => false));
    }
  }

  const formik = useFormik({
    initialValues: {
      restriction: "",
      value : ""
    },
    validationSchema: Yup.object({
      restriction: Yup.string().oneOf(restrictions.map(it => it.value)).required('Required'),
      value: Yup.string().test("isValid", "notValid", (val, ctx) => {
        if (!val) {
          return false;
        }
        if (ctx.parent.restriction === "restrictionSchemaId") {
          return unqualifiedSchemaIdRegex.test(val);
        }
        else if (ctx.parent.restriction === "restrictionCredDefId") {
          return unqualifiedCredentialDefinitionIdRegex.test(val);
        }
        else if (ctx.parent.restriction === "restrictionIssuerDid") {
          return unqualifiedIndyDidRegex.test(val);
        }
        return false;
      })
    }),
    onSubmit: addRestriction
  });

  const [lastValues, updateState] = useState(formik.values);

  const submitForm = useCallback(
    debounce(
      (): void => {
        formik.submitForm();
      },
      500,
      { maxWait: 1500 }
    ),
    []
  );

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);
    const valuesEqualInitialValues = isEqual(formik.values, formik.initialValues);
    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }
    if (!valuesEqualLastValues && !valuesEqualInitialValues && formik.isValid) {
      submitForm();
    }
    return () => {
      submitForm.cancel();
    }
  }, [formik.values, formik.isValid]);

  const onRestrictionChange = (e: React.SyntheticEvent, value: RestrictionOption|null) => {
    setValueLabelKey(() => value?.value ?? "restrictionEmpty");
    formik.setFieldValue("restriction", value?.value ?? "");
    // Reset value field
    formik.setFieldValue("value", "");
  }

  const onValueChange = (e: React.SyntheticEvent, value: AvailableRecord|string|null) => {
    const val = value !== null ? (typeof value === "string" ? value : value.record_id) : "";
    formik.setFieldValue("value", val);
    formik.setFieldTouched("value", true);
  }

  useEffect(() => {
    // console.log("formik.values", formik.values);
    formik.validateForm();
  }, [formik.values]);

  // useEffect(() => {
  //   console.log("formik.errors", formik.errors);
  // }, [formik.errors]);

  // useEffect(() => {
  //   console.log("formik.touched", formik.touched);
  // }, [formik.touched]);

  const filter = createFilterOptions<AvailableRecordOption>();

  const SelectValueForRestrictionType = (restriction: string) => (
    <Grid container>
      <Grid item xs sx={{mb: tokens.spacing.xs}}>
        <FormControl fullWidth
          error={formik.touched.value && Boolean(formik.errors.value)}
        >
          <Autocomplete
            id={`restriction-input-${restriction}`}
            options={getOptions(formik.values.restriction) ?? []}
            getOptionLabel={option => {
              if (typeof option === "string") {
                return option;
              }
              return option.record_id;
            }}
            onChange={onValueChange}
            onInput={e => console.log("onInput", e)}
            onBlur={formik.handleBlur}
            freeSolo
            autoSelect
            renderInput={(params) => (
              <TextField
                name="value"
                label={t(`app.components.AddProofRestrictionForm.${valueLabelKey}`)}
                onBlur={formik.handleBlur}
                {...params}
                error={formik.touched.value && Boolean(formik.errors.value)}
                required
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <ListItemText 
                  primary={option.title ?? option.label} 
                  secondary={option.record_id}
                  secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                />
              </Box>
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.record_id);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  title: `Add "${inputValue}"`,
                  record_id: inputValue,
                  label: inputValue,
                  description: ""
                });
              }
      
              return filtered;
            }}
          />
          { formik.touched.value && Boolean(formik.errors.value) && 
            <FormHelperText>{t(`common.validation.${formik.errors.value}`)}</FormHelperText> 
          }
        </FormControl>
      </Grid>
      { loading && 
        <Grid item xs="auto" sx={{mb: tokens.spacing.xs, ml: tokens.spacing.xs}}>
          <CircularProgress />
        </Grid>
      }
    </Grid>
  )

  return (
    <Paper sx={{padding: tokens.spacing.xs, background: tokens.colors.depthLight3}}>
      <Grid container>
        <Grid item xs sx={{mb: tokens.spacing.xs}}>
          <FormControl fullWidth 
            error={formik.touched.restriction && Boolean(formik.errors.restriction)}
          >
            <Autocomplete
              options={restrictions}
              getOptionLabel={option => t(`app.components.AddProofRestrictionForm.${option.value}`)}
              onChange={onRestrictionChange}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  name="restriction"
                  label={t("app.components.AddProofRestrictionForm.selectRestriction")}
                  {...params}
                  error={formik.touched.restriction && Boolean(formik.errors.restriction)}
                  required
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <ListItemText 
                    primary={t(`app.components.AddProofRestrictionForm.${option.value}`)} 
                    secondary={t(`app.components.AddProofRestrictionForm.${option.value}Description`)}
                    secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                  />
                </Box>
              )}
            />
            { formik.touched.restriction && Boolean(formik.errors.restriction) && 
              <FormHelperText>{t(`common.validation.${formik.errors.restriction}`)}</FormHelperText> 
            }
          </FormControl>
        </Grid>
        <Grid item xs="auto" sx={{mb: tokens.spacing.xs, ml: tokens.spacing.xs}}>
          <IconButton
            color="primary"
            onClick={onCancel}
          >
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs sx={{mb: tokens.spacing.xs}}>
        <FormHelperText>{t("app.components.RequestedProofDialog.proofRestrictionHelptext")}</FormHelperText>
      </Grid>
      { formik.values.restriction === "restrictionSchemaId" && SelectValueForRestrictionType(formik.values.restriction)}
      { formik.values.restriction === "restrictionCredDefId" && SelectValueForRestrictionType(formik.values.restriction)}
      { formik.values.restriction === "restrictionIssuerDid" && SelectValueForRestrictionType(formik.values.restriction)}
    </Paper>
  );
  
}

export default AddProofRestrictionForm;