import React from 'react';
import { ListItemText } from '@mui/material';
import { ConnRecord, V10CredentialExchange, V10PresentationExchange, V20CredExRecordDetail, V20PresExRecord } from '../../../models/AcaPyModels';
import { BasicMessageWrapper } from '../../../models/CloudWalletTypes';
import { useAppStateContext } from '../../../state/AppStateContext';
import { getConnectionLabel } from '../../../utils/walletUtils';
import { WalletActivity, WalletActivityType } from './ActivityView';

const ActivityConnectionLabel: React.FC<{activity: WalletActivity}> = ({activity}) => {
  const appContext = useAppStateContext();

  const getActivityLabel = (activity: WalletActivity): string => {
    switch (activity.type) {
      case WalletActivityType.BASIC_MESSAGE:
        const msg = activity.data as BasicMessageWrapper;
        return getConnectionLabel(appContext.connections.find( c => c.connection_id === msg.message.connection_id));
      case WalletActivityType.CONNECTION:
        const conn = activity.data as ConnRecord;
        return getConnectionLabel(conn);
      case WalletActivityType.V10_CRED_EXCHANGE:
        const v10cred = activity.data as V10CredentialExchange;
        return getConnectionLabel(appContext.connections.find( c => c.connection_id === v10cred.connection_id));
      case WalletActivityType.V20_CRED_EXCHANGE:
        const v20cred = activity.data as V20CredExRecordDetail;
        return getConnectionLabel(appContext.connections.find( c => c.connection_id === v20cred.cred_ex_record?.connection_id));
      case WalletActivityType.V10_PROOF_REQUEST:
        const v10proof = activity.data as V10PresentationExchange;
        return getConnectionLabel(appContext.connections.find( c => c.connection_id === v10proof.connection_id));
      case WalletActivityType.V20_PROOF_REQUEST:
        const v20proof = activity.data as V20PresExRecord;
        return getConnectionLabel(appContext.connections.find( c => c.connection_id === v20proof.connection_id));
    }
  }
  
  return (
    <ListItemText primaryTypographyProps={{variant: "button"}} primary={getActivityLabel(activity)}/>
  );
}

export default ActivityConnectionLabel;