import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText, Paper, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { BasicMessageWrapper } from '../../../models/CloudWalletTypes';
import { useAppStateContext } from '../../../state/AppStateContext';
import { getConnectionLabel } from '../../../utils/walletUtils';
import { toDateTimeString } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router';

const BasicMessageDetails: React.FC<{msg: BasicMessageWrapper}> = ({msg}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  
  return (
    <Grid container spacing={1} sx={{alignItems: "center"}}>
      <Grid item container xs={12} md={6}>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.BasicMessageDetails.messageId")}/>
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={msg.message.message_id}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.BasicMessageDetails.from")}
        </Grid>
        <Grid item xs={6}>
          {msg.message.state === "received" ? getConnectionLabel(appContext.connections.find( c => c.connection_id === msg.message.connection_id)) : appContext.company?.companyName }
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.BasicMessageDetails.sent")}
        </Grid>
        <Grid item xs={6}>
          {toDateTimeString(msg.message.sent_time)}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.BasicMessageDetails.to")}
        </Grid>
        <Grid item xs={6}>
          {msg.message.state === "received" ? appContext.company?.companyName : getConnectionLabel(appContext.connections.find( c => c.connection_id === msg.message.connection_id)) }
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6}>
        <Grid item xs={12}>
          <Paper sx={{padding: tokens.spacing.xs, backgroundColor: "#F9FAFB"}}>
            <Typography>
              {msg.message.content.length > 150 ? msg.message.content.substring(0, 150) + "..." : msg.message.content}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Button variant="contained" onClick={() => navigate(`/messages/${msg.message.connection_id}`)}>
          {t("app.components.BasicMessageDetails.openInMessages")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default BasicMessageDetails;