import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { ConnRecord, V10CredentialExchange, V10PresentationExchange, V20CredExRecordDetail, V20PresExRecord } from '../../../models/AcaPyModels';
import { BasicMessageWrapper } from '../../../models/CloudWalletTypes';
import { useAppStateContext } from '../../../state/AppStateContext';
import { getUpdatedAt } from '../../../utils/walletUtils';
import Page from '../../common/Page';
import ActivityList from './ActivityList';

export interface WalletActivity {
  type: WalletActivityType,
  data: BasicMessageWrapper | ConnRecord | V10CredentialExchange | V20CredExRecordDetail | V10PresentationExchange | V20PresExRecord
}

export enum WalletActivityType {
  BASIC_MESSAGE = "basicMessage",
  CONNECTION = "connection",
  V10_CRED_EXCHANGE = "v10CredExchange",
  V20_CRED_EXCHANGE = "v20CredExchange",
  V10_PROOF_REQUEST = "v10ProofRequest",
  V20_PROOF_REQUEST = "v20ProofRequest",
}

const ActivityView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [activities, setActivities] = useState<WalletActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [backdrop, setBackdrop] = useState<boolean>(false);

  useEffect(() => {
    listAllActivities();
  }, [appContext.isWalletLoaded]);

  const refresh = () => {
    setLoading(() => true);
    appContext.refreshWalletState()
    .then(() => {
      listAllActivities();
      setLoading(() => false);
    });
  }

  const sortActivities = (activities: WalletActivity[]) => {
    activities.sort((a,b) => {
      const aCreatedAtStr = getUpdatedAt(a);
      const aCreatedAt = aCreatedAtStr ? Date.parse(aCreatedAtStr) : undefined;
      const bCreatedAtStr = getUpdatedAt(b);
      const bCreatedAt = bCreatedAtStr ? Date.parse(bCreatedAtStr) : undefined;
      if (aCreatedAt && bCreatedAt) {
        return bCreatedAt - aCreatedAt;
      }
      else if (aCreatedAt && !bCreatedAt) {
        return 1;
      }
      else if (!aCreatedAt && bCreatedAt) {
        return -1;
      }
      return 0;
    });
  }

  const listAllActivities = () => {
    const results: WalletActivity[] = [];
    results.push(...appContext.connections.map(c => ({ type: WalletActivityType.CONNECTION, data: c} as WalletActivity)));
    results.push(...Object.entries(appContext.messages).map(([key, value]) => value).flatMap(value => value).map(m => ({ type: WalletActivityType.BASIC_MESSAGE, data: m} as WalletActivity)));
    if (appContext.credentialExchanges?.v10results) {
      results.push(...appContext.credentialExchanges.v10results.map(c => ({ type: WalletActivityType.V10_CRED_EXCHANGE, data: c} as WalletActivity)));
    }
    if (appContext.credentialExchanges?.v20results) {
      results.push(...appContext.credentialExchanges.v20results.map(c => ({ type: WalletActivityType.V20_CRED_EXCHANGE, data: c} as WalletActivity)));
    }
    if (appContext.presentationExchanges?.v10results) {
      results.push(...appContext.presentationExchanges.v10results.map(p => ({ type: WalletActivityType.V10_PROOF_REQUEST, data: p} as WalletActivity)));
    }
    if (appContext.presentationExchanges?.v20results) {
      results.push(...appContext.presentationExchanges.v20results.map(p => ({ type: WalletActivityType.V20_PROOF_REQUEST, data: p} as WalletActivity)));
    }
    sortActivities(results);
    setActivities(() => results);
  }
  
  return (
    <Page 
      title={t("app.components.ActivityView.title")}
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/activity", label: "activity", current: true}
      ]}
      titleAction={
        <Button 
          startIcon={loading ? <HourglassEmptyIcon/> : <RefreshIcon/>}
          onClick={refresh}
        >
          {loading ? t("common.actions.refreshing.title") : t("common.actions.refresh.title")}
        </Button>
      }
    >
      <ActivityList activities={activities} />
    </Page>
  );
}

export default ActivityView;