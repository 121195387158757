import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Paper, Select, TextField } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { IndyProofReqAttrSpec, V10PresentationSendRequestRequest } from "../../../models/AcaPyModels";
import { useAppStateContext } from "../../../state/AppStateContext";
import RequestedProofDialog from "./RequestedProofDialog";
import { getConnectionLabel } from "../../../utils/walletUtils";
import { useTranslation } from "react-i18next";
import RequestedProofItem from "./RequestedProofItem";

export interface RequestedProof {
  name: string,
  value: IndyProofReqAttrSpec
}

const RequestedProofSchema: Yup.ObjectSchema<RequestedProof> = Yup.object({
  name: Yup.string().required("Required"),
  value: Yup.object().required("Required")
})

interface Props {
  connectionId?: string,
  onSubmit: (request: V10PresentationSendRequestRequest) => Promise<void>
}

const PresentationRequestForm: React.FC<Props> = ({connectionId, onSubmit}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [openAddProofDialog, setOpenAddProofDialog] = useState<boolean>(false);

  const handleSubmit = () => {
    const requestedAttributes = formik.values.requestedProofs.reduce((attrs, current) => ({...attrs, [current.name]: current.value}), {} as {[key: string]: IndyProofReqAttrSpec});
    const proofRequest: V10PresentationSendRequestRequest = {
      auto_verify: false,
      comment: formik.values.comment,
      connection_id: formik.values.connectionId,
      proof_request: {
        name: formik.values.proofRequestName,
        requested_attributes: requestedAttributes,
        requested_predicates: {},
        version: "1.0"
      }
    };
    onSubmit(proofRequest);
  }

  const formik = useFormik({
    initialValues: {
      connectionId: "",
      proofRequestName : "",
      comment: "",
      requestedProofs: [] as RequestedProof[]
    },
    validationSchema: Yup.object({
      connectionId: Yup.string().oneOf(appContext.getActiveConnections().map(c => c.connection_id)).required('Required'),
      proofRequestName: Yup.string().required('Required'),
      comment: Yup.string(),
      requestedProofs: Yup.array().of(RequestedProofSchema).min(1, "minValueError").required("Required")
    }),
    onSubmit: handleSubmit
  });
  
  const addRequestedProof = (name: string, value: IndyProofReqAttrSpec) => {
    let requestedProofs = formik.values.requestedProofs;
    requestedProofs.push({name, value});
    formik.setFieldValue("requestedProofs", requestedProofs);
    setOpenAddProofDialog(() => false);
  }

  const updateRequestedProof = (proofIndex: number, proof: RequestedProof) => {
    let requestedProofs = formik.values.requestedProofs;
    requestedProofs[proofIndex] = proof;
    formik.setFieldValue("requestedProofs", requestedProofs);
  }

  const removeRequestedProof = (index: number) => {
    let requestedProofs = formik.values.requestedProofs;
    requestedProofs.splice(index, 1);
    formik.setFieldValue("requestedProofs", requestedProofs);
  }

  const availableConnections = () => {
    return appContext.getActiveConnections()
    .map(connRecord => (
      <MenuItem key={"conn-" + connRecord.connection_id} value={connRecord.connection_id}>
        {getConnectionLabel(connRecord)}
      </MenuItem>
      )
    );
  }

  return (
    <Paper elevation={3} sx={{padding: tokens.spacing.xs, display: "flex", flexDirection: "column"}}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container component={FormControl} fullWidth 
          error={formik.touched.connectionId && Boolean(formik.errors.connectionId)}
          sx={{mb: tokens.spacing.xs}}
        >
          <Grid item xs={12} sm={4} alignSelf="center">
            <FormLabel>{t("app.components.PresentationRequestForm.selectConnection")}</FormLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select 
              name="connectionId" 
              value={formik.values.connectionId} 
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              variant="outlined" 
              required
              fullWidth 
            >
              {
                availableConnections()
              }
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={8}>
            { formik.touched.connectionId && Boolean(formik.errors.connectionId) && 
              <FormHelperText>{t(`common.validation.${formik.errors.connectionId}`)}</FormHelperText> 
            }
          </Grid>
        </Grid>
        <Grid container component={FormControl} fullWidth 
          error={formik.touched.proofRequestName && Boolean(formik.errors.proofRequestName)}
          sx={{mb: tokens.spacing.xs}}
        >
          <Grid item xs={12} sm={4} alignSelf="center">
            <FormLabel>{t("app.components.PresentationRequestForm.proofRequestName")}</FormLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField 
              name="proofRequestName" 
              value={formik.values.proofRequestName} 
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              variant="outlined" 
              required
              fullWidth 
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={8}>
            { formik.touched.proofRequestName && Boolean(formik.errors.proofRequestName) && 
              <FormHelperText>{t(`common.validation.${formik.errors.proofRequestName}`)}</FormHelperText> 
            }
          </Grid>
        </Grid>
        <Grid container component={FormControl} fullWidth 
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          sx={{mb: tokens.spacing.xs}}
        >
          <Grid item xs={12} sm={4} alignSelf="center">
            <FormLabel>{t("app.components.PresentationRequestForm.comment")}</FormLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField 
              name="comment" 
              value={formik.values.comment} 
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              variant="outlined" 
              fullWidth 
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={8}>
            { formik.touched.comment && Boolean(formik.errors.comment) && 
              <FormHelperText>{String(formik.errors.comment)}</FormHelperText> 
            }
          </Grid>
        </Grid>
        <Grid container sx={{mb: tokens.spacing.xs}}>
          { formik.values.requestedProofs.map((proof, proofIndex) => 
            <Grid item xs={12} key={`proof-${proofIndex}`} alignSelf="center">
              <RequestedProofItem proofIndex={proofIndex} proof={proof} onUpdate={updateRequestedProof}/>
            </Grid>
          )}
        </Grid>
        <Grid container sx={{mb: tokens.spacing.xs}}>
          <Grid item xs={12} alignSelf="center">
            <Button onClick={() => setOpenAddProofDialog(() => true)}>
              {t("app.components.PresentationRequestForm.addProofBtn")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={8}>
            { formik.touched.requestedProofs && Boolean(formik.errors.requestedProofs) && 
              <FormHelperText>{String(formik.errors.requestedProofs)}</FormHelperText> 
            }
          </Grid>
        </Grid>
        <Grid container sx={{mb: tokens.spacing.xs, justifyContent: "flex-end"}}>
          <Button type="submit" variant="contained" >
            {t("app.components.PresentationRequestForm.sendBtn")}
          </Button>
        </Grid>
      </form>
      { openAddProofDialog &&
        <RequestedProofDialog 
          open={openAddProofDialog}
          onSubmit={addRequestedProof}
          onCancel={() => setOpenAddProofDialog(() => false)}
        />
      }
    </Paper>
  );
}

export default PresentationRequestForm;