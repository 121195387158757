import React from 'react';
import { Grid, Paper } from '@mui/material';
import { CredCell, CredCellContent } from '../CredentialView';
import ReactJson from 'react-json-view';

interface Props {
  credential: any,
  visualize: boolean
}

const W3CCredentialView: React.FC<Props> = ({credential, visualize}) => {

  return (
    <Paper elevation={3} sx={{backgroundColor: "#F9FAFB"}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CredCell>
            <CredCellContent>
              { visualize && <ReactJson src={credential} name={false} collapsed={2} displayDataTypes={false}/> }
              { !visualize && <>{JSON.stringify(credential)}</> }
            </CredCellContent>
          </CredCell>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default W3CCredentialView;  