import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";

const LoadingBackdrop: React.FC<{open: boolean}> = ({open}) => {
  return (
    <Backdrop open={open} sx={{color: tokens.colors.brandBase, zIndex: 2000}}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  )
}

export default LoadingBackdrop;