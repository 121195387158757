import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../../state/AppStateContext';
import { Button, TextField, Typography } from '@mui/material';
import { FlexColTight } from '../../common/CommonStyles';
import { ConnRecord } from '../../../models/AcaPyModels';
import ConnectionStateAlert from './ConnectionStateAlert';
import useInterval from '../../../utils/useInterval';

const NewPublicDIDConnection: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [publicDID, setPublicDID] = useState<string>("");
  const [alias, setAlias] = useState<string>("");
  const [publicDidError, setPublicDidError] = useState<string|undefined>(undefined);
  const [connection, setConnection] = useState<ConnRecord|undefined>(undefined);
  const [connState, setConnState] = useState<string|undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);

  useInterval(async () => {
    if (connection && connection.state !== "active") {
      appContext.getConnectionsAsync()
      .then(connections => {
        const match = connections.find(c => c.connection_id === connection.connection_id);
        if (match) {
          setConnection(() => match);
          setConnState(() => match.state);
          if (match.state === "active" || match.state === "abandoned") {
            setRefresh(() => false);
          }
        }
      });
    }
  }, refresh ? 3000 : null);

  const onConfirm = (did: string) => {
    if (!did || !did.startsWith("did:sov:")) {
      setPublicDidError(() => "invalidDid");
    }
    else {
      setPublicDidError(() => undefined);
      appContext.getCreatePublicDidConnection(did, alias ?? undefined)
      .then(connRecord => {
        if (connRecord) {
          setConnection(() => connRecord);
          setConnState(() => connRecord.state);
          setRefresh(() => true);
        }
        else {
          setConnection(() => undefined);
          setConnState(() => "error");
        }
      });
    }
  }

  return connState ? 
  <ConnectionStateAlert state={connState}/> :
  <FlexColTight>
    <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 1}}>
      {t("app.components.NewPublicDIDConnection.aliasHelpText")}
    </Typography>
    <TextField
      label={t("app.components.NewPublicDIDConnection.aliasLabel")}
      value={alias}
      onChange={(e) => setAlias(e.target.value)}
      sx={{pb: 2}}
    />
    <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 1}}>
      {t("app.components.NewPublicDIDConnection.helpText")}
    </Typography>
    <TextField
      label={t("app.components.NewPublicDIDConnection.didLabel")}
      value={publicDID}
      error={!!publicDidError}
      helperText={publicDidError && t(`app.components.NewPublicDIDConnection.${publicDidError}`)}
      onChange={(e) => setPublicDID(e.target.value)}
    />
    <Button
      variant="contained"
      onClick={() => onConfirm(publicDID)}
      sx={{ mt: 1 }}
    >{t("common.actions.confirm.title")}</Button>
  </FlexColTight>;
}

export default NewPublicDIDConnection;