import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../../state/AppStateContext';
import { Alert, AlertColor, AlertTitle, CircularProgress, Container } from '@mui/material';
import Page from '../../common/Page';
import { ConnRecord, V10PresentationExchange, V10PresentationSendRequestRequest } from '../../../models/AcaPyModels';
import PresentationRequestForm from './PresentationRequestForm';

interface Status {
  severity: AlertColor,
  titleKey: string,
  messageKey: string
}

const NewPresentationRequestView: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [connection, setConnection] = useState<ConnRecord|undefined>(undefined);
  const [connectionStatus, setConnectionStatus] = useState<Status|undefined>(undefined);
  const [presEx, setPresEx] = useState<V10PresentationExchange|undefined>(undefined);

  const onSubmit = async (request: V10PresentationSendRequestRequest) => {
    setConnectionStatus(() => ({
      severity: "info",
      titleKey: "app.components.NewPresentationRequestView.sendingRequestTitle",
      messageKey: "app.components.NewPresentationRequestView.sendingRequestMsg"
    }));
    appContext.sendPresentationRequestAsync(request)
    .then(presExchange => {
      if (presExchange) {
        setPresEx(() => presExchange);
        setConnectionStatus(() => ({
          severity: "success",
          titleKey: "app.components.NewPresentationRequestView.requestSentTitle",
          messageKey: "app.components.NewPresentationRequestView.requestSentMsg"
        }));
      }
      else {
        setConnectionStatus(() => ({
          severity: "error",
          titleKey: "app.components.NewPresentationRequestView.failedToSendRequestTitle",
          messageKey: "app.components.NewPresentationRequestView.failedToSendRequestMsg"
        }))
      }
    });
  }

  const getContent = () => {
    if (connectionStatus) {
      return (
        <Container maxWidth="sm">
          <Alert severity={connectionStatus.severity} icon={connectionStatus.severity === "info" && <CircularProgress/>}>
            <AlertTitle>{t(connectionStatus.titleKey)}</AlertTitle>
            {t(connectionStatus.messageKey)}
          </Alert>
        </Container>
      );
    }
    return <PresentationRequestForm connectionId={connection?.connection_id} onSubmit={onSubmit} />;
  }

  return (
    <Page 
      title={t("app.components.NewPresentationRequestView.title")}
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/presentations", label: "presentations", current: false},
        { href: "/presentation", label: "new-presentation", current: true}
      ]}
    >
      { getContent() }
    </Page>
  );
}

export default NewPresentationRequestView;