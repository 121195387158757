import React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { IndyProofReqAttrSpec } from "../../../models/AcaPyModels";
import { useTranslation } from "react-i18next";
import AddProofRestrictionForm from "./AddProofRestrictionForm";

interface RestrictionItem {
  key: string,
  value: string
}

const RestrictionItemSchema: Yup.ObjectSchema<RestrictionItem> = Yup.object({
  key: Yup.string().required("Required"),
  value: Yup.string().required("Required")
})

interface Props {
  open: boolean,
  onSubmit: (key: string, value: IndyProofReqAttrSpec) => void
  onCancel: () => void
}

const RequestedProofDialog: React.FC<Props> = ({open, onSubmit, onCancel}) => {
  const { t } = useTranslation(["translation"]);

  const handleSubmit = () => {
    const proofResp: IndyProofReqAttrSpec = {
      restrictions: formik.values.restrictions
    }
    onSubmit(formik.values.proofLabel, proofResp);
  }

  const formik = useFormik({
    initialValues: {
      proofLabel: "",
      openRestrictionForm: false,
      restrictions : [] as Array<{ [key: string]: string; }>
    },
    validationSchema:       Yup.object({
      proofLabel:           Yup.string().required('Required'),
      openRestrictionForm:  Yup.boolean().oneOf([false], "restrictionFormOpen").required('Required'),
      restrictions:         Yup.array()
    }),
    onSubmit: handleSubmit
  });

  const addRestriction = (key: string, value: string) => {
    const restrictions = formik.values.restrictions;
    restrictions.push({[key]: value});
    formik.setFieldValue("restrictions", restrictions);
    formik.setFieldValue("openRestrictionForm", false);
  }

  const deleteRestriction = (index: number) => {
    let restrictions = formik.values.restrictions;
    restrictions.splice(index, 1);
    formik.setFieldValue("restrictions", restrictions);
  }

  return (
    <Dialog open={open}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
      <DialogTitle>
        {t("app.components.RequestedProofDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item xs={12} component={FormControl} fullWidth 
            error={formik.touched.proofLabel && Boolean(formik.errors.proofLabel)}
            sx={{mb: tokens.spacing.xs}}
          >
            <Grid item xs={12} alignSelf="center">
              <FormLabel>{t("app.components.RequestedProofDialog.proofLabel")}</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="proofLabel" 
                value={formik.values.proofLabel} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur} 
                variant="outlined" 
                placeholder={t("app.components.RequestedProofDialog.proofLabelPlaceholder")}
                required
                fullWidth 
                sx={{background: tokens.colors.whiteBase}}
              />
            </Grid>
            <Grid item xs={12}>
              { formik.touched.proofLabel && Boolean(formik.errors.proofLabel) && 
                <FormHelperText>{t(`common.validation.${formik.errors.proofLabel}`)}</FormHelperText> 
              }
            </Grid>
          </Grid>
          <Grid container item xs={12} component={FormControl} fullWidth 
            error={(formik.touched.restrictions && Boolean(formik.errors.restrictions)) || 
                    (formik.touched.openRestrictionForm && Boolean(formik.errors.openRestrictionForm))}
            sx={{mb: tokens.spacing.xs}}
          >
            <Grid item xs={12} alignSelf="center">
              <FormLabel>{t("app.components.RequestedProofDialog.proofRestriction")}</FormLabel>
              <FormHelperText>{t("app.components.RequestedProofDialog.proofRestrictionHelptext")}</FormHelperText>
            </Grid>
            <Grid component={List} disablePadding item xs={12}>
            { formik.values.restrictions.map((r, rIndex) => {
              return Object.entries(r).map(([key, value], entryIndex) =>
                <ListItem key={`restriction-${rIndex}-${entryIndex}`} secondaryAction={
                  <IconButton edge="end" onClick={() => deleteRestriction(rIndex)}>
                    <DeleteIcon/>
                  </IconButton>
                }>
                  <ListItemText primary={key} secondary={value}></ListItemText>
                </ListItem>
              )
            })}
            </Grid>
            <Grid item xs={12}>
              { formik.values.openRestrictionForm &&
                <AddProofRestrictionForm onSubmit={addRestriction} onCancel={() => formik.setFieldValue("openRestrictionForm", false)} />
              }
              { formik.values.restrictions.length === 0 && !formik.values.openRestrictionForm && 
                <Button onClick={() => formik.setFieldValue("openRestrictionForm", true)}>{t("app.components.RequestedProofDialog.addRestrictionBtn")}</Button>
              }
            </Grid>
            <Grid item xs={12}>
              { formik.touched.restrictions && Boolean(formik.errors.restrictions) && 
                <FormHelperText>{String(formik.errors.restrictions)}</FormHelperText> 
              }
              { formik.touched.openRestrictionForm && Boolean(formik.errors.openRestrictionForm) && 
                <FormHelperText>{t("app.components.RequestedProofDialog.restrictionFormOpenError")}</FormHelperText> 
              }
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button variant="contained" onClick={onCancel}>{t("common.actions.cancel.title")}</Button>
        <Button variant="contained" type="submit">{t("app.components.RequestedProofDialog.addProofBtn")}</Button>
      </DialogActions>
      </form>
    </Dialog>
  );
}

export default RequestedProofDialog;