import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, Card, Chip, FormControl, FormHelperText, FormLabel, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from "../../../state/AppStateContext";
import { useTranslation } from "react-i18next";
import { debounce, isEqual } from "lodash";
import { RequestedProof } from "./PresentationRequestForm";

interface Props {
  proofIndex: number,
  proof: RequestedProof,
  onUpdate: (proofIndex: number, proof: RequestedProof) => void
}

const RequestedProofItem: React.FC<Props> = ({proofIndex, proof, onUpdate}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    const schemaRestriction = proof.value.restrictions?.find(it => {
      return it["schema_id"] !== undefined;
    });
    if (schemaRestriction) {
      const schema_id = schemaRestriction["schema_id"];
      appContext.getSchemaAsync(schema_id)
      .then(schema => {
        if (schema && schema.attrNames) {
          setOptions(() => schema.attrNames!);
        }
      })
    }
    const credDefRestriction = proof.value.restrictions?.find(it => {
      return it["cred_def_id"] !== undefined;
    });
    if (credDefRestriction) {
      const cred_def_id = credDefRestriction["cred_def_id"];
      appContext.getCredentialDefinitionAsync(cred_def_id)
      .then(credDef => {
        if (credDef && credDef.schemaId) {
          return appContext.getSchemaAsync(credDef.schemaId);
        }
      })
      .then(schema => {
        if (schema && schema.attrNames) {
          setOptions(() => schema.attrNames!);
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    const proofResp: RequestedProof = {
      ...proof,
      value: {
        ...proof.value,
        names: formik.values.names
      }
    };
    onUpdate(proofIndex, proofResp);
  }

  const formik = useFormik({
    initialValues: {
      names: []
    },
    validationSchema: Yup.object({
      names: Yup.array().of(Yup.string()).min(1).required('Required')
    }),
    onSubmit: handleSubmit
  });

  const onOptionsChange = (e: React.SyntheticEvent, values: string[]) => {
    formik.setFieldValue("names", values);
  }

  const [lastValues, updateState] = useState(formik.values);

  const submitForm = useCallback(
    debounce(
      (): void => {
        formik.submitForm();
      },
      500,
      { maxWait: 1500 }
    ),
    []
  );

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);
    const valuesEqualInitialValues = isEqual(formik.values, formik.initialValues);
    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }
    if (!valuesEqualLastValues && !valuesEqualInitialValues && formik.isValid) {
      submitForm();
    }
    return () => {
      submitForm.cancel();
    }
  }, [formik.values, formik.isValid]);

  return (
    <Card sx={{padding: tokens.spacing.xs, background: tokens.colors.depthLight3}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="button">{proof.name}</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={4}>
            <FormLabel>{t("app.components.RequestedProofItem.restrictions")}</FormLabel>
          </Grid>
          <Grid item xs={12} sm={8} component={List} disablePadding>
            { proof.value.restrictions?.map((r, rIndex) => {
              return Object.entries(r).map(([key, value], entryIndex) =>
                <ListItem key={`restriction-${rIndex}-${entryIndex}`} disablePadding>
                  <ListItemText primary={key} secondary={value} sx={{m: 0}}></ListItemText>
                </ListItem>
              )
            })}
          </Grid>
        </Grid>
        <Grid container item xs={12} component={FormControl} fullWidth 
          error={formik.touched.names && Boolean(formik.errors.names)}
          sx={{mb: tokens.spacing.xs}}
        >
          <Grid item xs={12} sm={4} alignSelf="center">
            <FormLabel>{t("app.components.RequestedProofItem.names")}</FormLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              multiple
              options={options}
              getOptionLabel={option => option}
              filterSelectedOptions
              onChange={onOptionsChange}
              onBlur={formik.handleBlur}
              freeSolo={options.length === 0}
              renderInput={(params) => (
                <TextField
                  name="names"
                  {...params}
                  // placeholder={t("app.components.RequestedProofItem.namesPlaceholder")}
                />
              )}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={8}>
            { formik.touched.names && Boolean(formik.errors.names) && 
              <FormHelperText>{String(formik.errors.names)}</FormHelperText> 
            }
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default RequestedProofItem;