import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText } from '@mui/material';
import { toDateTimeString } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router';
import { V20CredExRecord, V20CredExRecordDetail } from '../../../models/AcaPyModels';
import { getConnectionLabel, getCredentialId } from '../../../utils/walletUtils';
import { useAppStateContext } from '../../../state/AppStateContext';

const V20CredExchangeDetails: React.FC<{credEx: V20CredExRecordDetail}> = ({credEx}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  
  const isHolder = () => {
    return credEx.cred_ex_record?.role === V20CredExRecord.RoleEnum.Holder;
  }

  const getCredential = () => {
    return appContext.credentials?.find(c => getCredentialId(c) === credEx.indy?.cred_id_stored);
  }

  const getState = () => {
    if (i18n.exists(`app.models.V20CredExRecordState.${credEx.cred_ex_record?.state}`)) {
      let color = "info.main";
      if (credEx.cred_ex_record?.state === V20CredExRecord.StateEnum.Done) {
        color = "success.main";
      }
      if (credEx.cred_ex_record?.state === V20CredExRecord.StateEnum.Abandoned ||
        credEx.cred_ex_record?.state === V20CredExRecord.StateEnum.CredentialRevoked) {
          color = "error.main";
        }
      return <ListItemText primaryTypographyProps={{ color }} primary={t(`app.models.V20CredExRecordState.${credEx.cred_ex_record?.state}`)}/>;
    }
    return <ListItemText primaryTypographyProps={{ color: "error.main"}} primary={t("app.models.ConnRecordRFC23State.error") + ": " + credEx.cred_ex_record?.state}/>;
  }

  return (
    <Grid container spacing={1} sx={{alignItems: "center"}}>
      <Grid item container xs={12} md={6}>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.ActivityView.activityId")}/>
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={credEx.cred_ex_record?.cred_ex_id}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.issuer")}
        </Grid>
        <Grid item xs={6}>
          {isHolder() ? getConnectionLabel(appContext.connections.find( c => c.connection_id === credEx.cred_ex_record?.connection_id)) : appContext.company?.companyName}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.receiver")}
        </Grid>
        <Grid item xs={6}>
        {isHolder() ? appContext.company?.companyName : getConnectionLabel(appContext.connections.find( c => c.connection_id === credEx.cred_ex_record?.connection_id))}
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.state")}
        </Grid>
        <Grid item xs={6}>
          <ListItemText primaryTypographyProps={{variant: "caption"}} primary={getState()}/>
        </Grid>
        <Grid item xs={6} sx={{fontWeight: 700}}>
          {t("app.components.CredExchangeDetails.updatedAt")}
        </Grid>
        <Grid item xs={6}>
          {toDateTimeString(credEx.cred_ex_record?.updated_at)}
        </Grid>
      </Grid>
      { credEx.indy?.cred_id_stored && getCredential() &&
        <Grid item container xs={12}>
          <Button variant="contained" onClick={() => navigate(`/credential/${credEx.indy?.cred_id_stored ?? credEx.ld_proof?.cred_id_stored}`)}>
            {t("app.components.CredExchangeDetails.openCredential")}
          </Button>
        </Grid>
      }
      { credEx.indy?.cred_id_stored && !getCredential() &&
        <Grid item container xs={12}>
          <ListItemText primaryTypographyProps={{variant: "button", color: "error"}} primary={t("app.components.CredExchangeDetails.credentialDeleted")}/>
        </Grid>
      }
    </Grid>
  );
}

export default V20CredExchangeDetails;