import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import { getUpdatedAt } from '../../../utils/walletUtils';
import { WalletActivity, WalletActivityType } from '../Activity/ActivityView';
import ActivityList from '../Activity/ActivityList';
import { useNavigate } from 'react-router';

const PresentationsView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [activities, setActivities] = useState<WalletActivity[]>([]);

  useEffect(() => {
    appContext.getPresentationExchangesAsync();
  }, []);
  
  const refresh = () => {
    setLoading(() => true);
    appContext.getPresentationExchangesAsync()
    .then(() => {
      listAllActivities();
      setLoading(() => false);
    });
  }

  useEffect(() => {
    listAllActivities();
  }, [appContext.isWalletLoaded]);

  const sortActivities = (activities: WalletActivity[]) => {
    activities.sort((a,b) => {
      const aCreatedAtStr = getUpdatedAt(a);
      const aCreatedAt = aCreatedAtStr ? Date.parse(aCreatedAtStr) : undefined;
      const bCreatedAtStr = getUpdatedAt(b);
      const bCreatedAt = bCreatedAtStr ? Date.parse(bCreatedAtStr) : undefined;
      if (aCreatedAt && bCreatedAt) {
        return bCreatedAt - aCreatedAt;
      }
      else if (aCreatedAt && !bCreatedAt) {
        return 1;
      }
      else if (!aCreatedAt && bCreatedAt) {
        return -1;
      }
      return 0;
    });
  }

  const listAllActivities = () => {
    const results: WalletActivity[] = [];
    if (appContext.presentationExchanges?.v10results) {
      results.push(...appContext.presentationExchanges.v10results.map(p => ({ type: WalletActivityType.V10_PROOF_REQUEST, data: p} as WalletActivity)));
    }
    if (appContext.presentationExchanges?.v20results) {
      results.push(...appContext.presentationExchanges.v20results.map(p => ({ type: WalletActivityType.V20_PROOF_REQUEST, data: p} as WalletActivity)));
    }
    sortActivities(results);
    setActivities(() => results);
  }

  return (
    <Page 
      title={t("app.components.PresentationsView.title")}
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/presentations", label: "presentations", current: true}
      ]}
      titleAction={
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
          <Button 
            startIcon={ <AddCircleOutlineIcon/>}
            onClick={() => navigate("/presentations/new")}
          >
            {t("app.components.PresentationsView.newProofRequestBtn")}
          </Button>
          <Button 
            startIcon={loading ? <HourglassEmptyIcon/> : <RefreshIcon/>}
            onClick={refresh}
          >
            {loading ? t("common.actions.refreshing.title") : t("common.actions.refresh.title")}
          </Button>
        </Box>
      }
    >
      <ActivityList activities={activities} />
    </Page>
  )
}

export default PresentationsView;