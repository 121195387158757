import React from 'react';
import { Box, Divider, Grid, Paper } from '@mui/material';
import CredentialAttributeContent from '../../CredentialAttributeContent';
import CopyToClipboardBtn from '../../CopyToClipboardBtn';
import { IndyCredInfo } from '../../../models/AcaPyModels';
import { CredCell, CredCellContent, CredCellRight } from '../CredentialView';

interface Props {
  credential: IndyCredInfo,
  visualize: boolean
}

const IndyCredentialView: React.FC<Props> = ({credential, visualize}) => {

  const getAttributesAsSortedArray = (attrs: { [key: string]: string; }) => {
    const array = Object.entries(attrs).map(([key, value]) => {
      return {key, value};
    });
    array.sort((a, b) => {
       // ignore upper and lowercase
      const A = a.key.toUpperCase();
      const B = b.key.toUpperCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });
    return array;
  }

  return (
    <Paper elevation={3} sx={{backgroundColor: "#F9FAFB"}}>
      <Grid container spacing={1}>
        {credential.attrs && getAttributesAsSortedArray(credential.attrs).map(attr => (
          <Grid item container key={`attr-${attr.key}`}>
            <Grid item xs={12} sm={3}>
              <CredCell>
                <CredCellContent>
                  {attr.key}
                </CredCellContent>
                <Box sx={{display: {xs: "block", sm: "none"}}}>
                  <CopyToClipboardBtn textToCopy={attr.value} tooltipKey={"app.components.CredentialView.copyToClipboard"} />
                </Box>
              </CredCell>
            </Grid>
            <Grid item xs={12} sm={8}>
              <CredCell>
                <CredCellContent>
                  <CredentialAttributeContent 
                    credential={credential!} 
                    attributeKey={attr.key} 
                    attributeValue={attr.value} 
                    visualize={visualize} 
                  />
                </CredCellContent>
              </CredCell>
            </Grid>
            <Grid item sm={1} sx={{display: {xs: "none", sm: "block"}}} className="CopyCredValueColumn">
              <CredCellRight>
                <CopyToClipboardBtn textToCopy={attr.value} tooltipKey={"app.components.CredentialView.copyToClipboard"} />
              </CredCellRight>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

export default IndyCredentialView;