import React from 'react';
import * as AcaPyTypes from '../models/AcaPyModels';
import EReceipt from './EReceipt';

interface Props {
  credential: AcaPyTypes.IndyCredInfo,
  attributeKey: string,
  attributeValue: string,
  visualize: boolean
}

enum CredDefs {
  eReceipt = "813BK6Wo5nPEZxAkswaHA7:3:CL:59:default"
}

const CredentialAttributeContent: React.FC<Props> = ({credential, attributeKey, attributeValue, visualize}) => {
  if (visualize) {
    if (credential.cred_def_id === CredDefs.eReceipt) {
      if (attributeKey === "receipt") {
        const invoice = JSON.parse(attributeValue);
        return <EReceipt invoice={invoice}/>;
      }
    }
  }

  // As default, return attribute value as plain string
  return <>{attributeValue}</>;
}

export default CredentialAttributeContent;