/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react'
import styled from "styled-components";
import { Paper, Typography, Grid, Container, List, ListItem, ListItemText } from '@mui/material';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components'
import { Invoice, InvoiceLine } from '../models/NsgInvoiceModels';
import { useTranslation } from 'react-i18next';

const styles = {
  paper: css({
    padding: tokens.spacing.s,
  })
}

const Text = styled(Typography)`
  font-size: 0.9rem;
`

const BoldText = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 700;
`

const EReceipt: React.FC<{invoice: Invoice}> = ({invoice}) => {
  const { t } = useTranslation(["translation"]);

  const itemDetails = (line: InvoiceLine) => {
    return `${t("app.components.EReceipt.count")}: ${line.invoicedQuantity}`;
  }
  
  const priceDetails = (line: InvoiceLine) => {
    return `${t("app.components.EReceipt.tax")}: ${line.item.classifiedTaxCategory.percent}`;
  }

  return (
    <Container maxWidth="md" style={{padding: 0}}>
      <Paper elevation={3} css={styles.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BoldText>{t("app.components.EReceipt.receipt")}</BoldText>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{t("app.components.EReceipt.issued")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{invoice.issueDate}</Typography>
          </Grid>
          <Grid item xs={6}>
            <BoldText>{t("app.components.EReceipt.seller")}</BoldText>
          </Grid>
          <Grid item xs={6}>
            <Text>{invoice.accountingSupplierParty.party.partyName.name}</Text>
            <Text>{invoice.accountingSupplierParty.party.postaladdress.streetName}</Text>
            <Text>{invoice.accountingSupplierParty.party.postaladdress.postalZone} {invoice.accountingSupplierParty.party.postaladdress.cityName}</Text>
            <Text>{invoice.accountingSupplierParty.party.postaladdress.country.name}</Text>
          </Grid>
          { invoice.accountingCustomerParty.party.partyName &&
            <>
              <Grid item xs={6}>
                <BoldText>{t("app.components.EReceipt.customer")}</BoldText>
              </Grid>
              <Grid item xs={6}>
                <Text>{invoice.accountingCustomerParty.party.partyName.name}</Text>
                { invoice.accountingCustomerParty.party.postaladdress &&
                  <>
                    <Text>{invoice.accountingCustomerParty.party.postaladdress.streetName}</Text>
                    <Text>{invoice.accountingCustomerParty.party.postaladdress.postalZone} {invoice.accountingCustomerParty.party.postaladdress.cityName}</Text>
                    <Text>{invoice.accountingCustomerParty.party.postaladdress.country.name}</Text>
                  </>
                }
              </Grid>
            </>
          }
          <Grid item xs={12}>
            <List disablePadding>
              {invoice.invoiceLine.map((line, index) => (
                <ListItem key={line.iD} style={{ padding: 0,  margin: 0 }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <ListItemText primary={<Text>{line.item.name}</Text>} secondary={itemDetails(line)} />
                    </Grid>
                    <Grid item xs={6} style={{textAlign: "right"}}>
                      <ListItemText primary={<Text>{line.price.priceAmount.toFixed(2)} €</Text>} secondary={priceDetails(line)}/>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <BoldText>{t("app.components.EReceipt.taxExclusiveAmount")}</BoldText>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <Text>{invoice.legalMonetaryTotal.taxExclusiveAmount.toFixed(2)} €</Text>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <BoldText>{t("app.components.EReceipt.taxAmount")}</BoldText>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <Text>{(invoice.legalMonetaryTotal.chargeTotalAmount - invoice.legalMonetaryTotal.taxExclusiveAmount).toFixed(2)} €</Text>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <BoldText>{t("app.components.EReceipt.chargeTotalAmount")}</BoldText>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <Text>{invoice.legalMonetaryTotal.chargeTotalAmount.toFixed(2)} €</Text>
          </Grid>
          
        </Grid>
      </Paper>
    </Container>
  );
}

export default EReceipt;