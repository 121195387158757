/** @jsxImportSource @emotion/react */
import React from "react";
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useAppStateContext } from "../../state/AppStateContext";
import Layout from "../common/Layout";
import LoadingModal from "../modals/LoadingModal";
import { useTranslation } from "react-i18next";
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { Box, Divider, Grid, Typography } from "@mui/material";
import CopyToClipboardBtn from "../CopyToClipboardBtn";

export const GridCell = styled.div`
  display: flex;
`

export const GridCellRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const GridCellContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  word-break:break-all;
`

const WalletInfoItem: React.FC<{trKey: string, value: string}> = ({trKey, value}) => {
  const { t } = useTranslation(["translation"]);

  return (
    <Grid item container>
      <Grid item xs={12} sm={5} md={4}  sx={{display: "flex", alignItems: "center"}}>
        <GridCell>
          <GridCellContent>
            <Typography sx={{fontWeight: "bold"}}>
              {t(trKey)}
            </Typography>
          </GridCellContent>
          <Box sx={{display: {xs: "block", sm: "none"}}}>
            <CopyToClipboardBtn textToCopy={value} tooltipKey={"app.components.CredentialView.copyToClipboard"} />
          </Box>
        </GridCell>
      </Grid>
      <Grid item xs={12} sm={6} md={7} sx={{display: "flex", alignItems: "center"}}>
        <GridCellContent>
          {value}
        </GridCellContent>
      </Grid>
      <Grid item sm={1} md={1} sx={{display: {xs: "none", sm: "block"}}}>
        <GridCellRight>
          <CopyToClipboardBtn textToCopy={value} tooltipKey={"app.components.CredentialView.copyToClipboard"} />
        </GridCellRight>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

const Home: React.FC = () => {
  const appContext = useAppStateContext();
  const { t } = useTranslation(["translation"]);
  
  const styles = css({
    padding: tokens.spacing.m,
    boxSizing: 'border-box',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1200px',
    '@media (min-width: 992px)': {
      padding: tokens.spacing.xxl
    },
    '@media (max-width: 600px)': {
      padding: tokens.spacing.xxs
    }
  });

  return (
    <div css={styles}>
      <Layout title={appContext.company ? `${appContext.company.companyName}, ${appContext.company.code}` : ""}>
        <Grid container spacing={1}>
          { appContext.walletInfo?.ledger?.Label && 
            <WalletInfoItem trKey="app.frontpage.ledger" value={appContext.walletInfo.ledger.Label}/>
          }
          { appContext.walletInfo?.publicDID?.did && 
            <WalletInfoItem trKey="app.frontpage.publicDid" value={`did:sov:${appContext.walletInfo?.publicDID.did}`}/>
          }
          { appContext.walletInfo?.webDID && 
            <WalletInfoItem trKey="app.frontpage.webDid" value={appContext.walletInfo?.webDID}/>
          }
          { appContext.company?.peppolParticipantID &&
            <WalletInfoItem trKey="app.frontpage.peppolParticipantID" value={appContext.company?.peppolParticipantID}/>
          }
        </Grid>
        <LoadingModal isOpen={appContext.isLoadingApp} contentLabel={t("common.loadingApp")} message={t("common.loadingApp")}/>
      </Layout>
    </div>
  );
};

export default Home;
