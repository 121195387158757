import { WalletActivity, WalletActivityType } from "../components/pages/Activity/ActivityView";
import { ConnRecord, IndyCredInfo, V10CredentialExchange, V10PresentationExchange, V20CredExRecordDetail, V20PresExRecord, VCRecord } from "../models/AcaPyModels";
import { AppContextCredential, BasicMessageWrapper, CredentialType } from "../models/CloudWalletTypes";
import { WC3VerifiableCredential } from "../models/WaltidModels";

export const getConnectionLabel = (connection?: ConnRecord): string => {
  if (!connection) {
    return "";
  }
  if (connection.alias) {
    return connection.alias;
  }
  if (connection.their_label) {
    return connection.their_label;
  }
  if (connection.their_public_did) {
    return connection.their_public_did;
  }
  return connection.their_did || "";
}

export const getUpdatedAt = (activity: WalletActivity) => {
  switch (activity.type) {
    case WalletActivityType.BASIC_MESSAGE:
      const msg = activity.data as BasicMessageWrapper;
      return msg.message.sent_time;
    case WalletActivityType.CONNECTION:
      const conn = activity.data as ConnRecord;
      return conn.updated_at;
    case WalletActivityType.V10_CRED_EXCHANGE:
      const v10cred = activity.data as V10CredentialExchange;
      return v10cred.updated_at;
    case WalletActivityType.V20_CRED_EXCHANGE:
      const v20cred = activity.data as V20CredExRecordDetail;
      return v20cred.cred_ex_record?.updated_at;
    case WalletActivityType.V10_PROOF_REQUEST:
      const v10proof = activity.data as V10PresentationExchange;
      return v10proof.updated_at;
    case WalletActivityType.V20_PROOF_REQUEST:
      const v20proof = activity.data as V20PresExRecord;
      return v20proof.updated_at;
  }
}

export const getActivityId = (activity: WalletActivity): string => {
  switch (activity.type) {
    case WalletActivityType.BASIC_MESSAGE:
      const msg = activity.data as BasicMessageWrapper;
      return msg.message.message_id;
    case WalletActivityType.CONNECTION:
      const conn = activity.data as ConnRecord;
      return conn.connection_id!;
    case WalletActivityType.V10_CRED_EXCHANGE:
      const v10cred = activity.data as V10CredentialExchange;
      return v10cred.credential_exchange_id!;
    case WalletActivityType.V20_CRED_EXCHANGE:
      const v20cred = activity.data as V20CredExRecordDetail;
      return v20cred.cred_ex_record!.cred_ex_id!;
    case WalletActivityType.V10_PROOF_REQUEST:
      const v10proof = activity.data as V10PresentationExchange;
      return v10proof.presentation_exchange_id!;
    case WalletActivityType.V20_PROOF_REQUEST:
      const v20proof = activity.data as V20PresExRecord;
      return v20proof.pres_ex_id!;
  }
}

export const getW3CCredentialType = (w3cCred: VCRecord) => {
  let name = w3cCred.expanded_types?.find(it => it !== "https://www.w3.org/2018/credentials#VerifiableCredential");
  if (name?.lastIndexOf("#") && name?.lastIndexOf("#") < name.length) {
    name = name.substring(name.lastIndexOf("#") + 1);
  }
  return name ?? "";
}

export const getCredentialType = (cred: AppContextCredential) => {
  if (cred?.type === CredentialType.ACAPY_W3C_CREDENTIAL) {
    return getW3CCredentialType(cred.credential as VCRecord);
  }
  else if (cred?.type === CredentialType.WALTID_W3C_CREDENTIAL) {
    const _cred = cred.credential as WC3VerifiableCredential;
    return _cred.type[_cred.type.length -1];
  }
  const _cred = cred.credential as IndyCredInfo;
  return _cred.schema_id!.split(':')[2];
}

export const getCredentialId = (cred: AppContextCredential) => {
  if (cred.type === CredentialType.ACAPY_W3C_CREDENTIAL) {
    const _cred = cred.credential as VCRecord;
    return _cred.record_id;
  }
  else if (cred.type === CredentialType.WALTID_W3C_CREDENTIAL) {
    const _cred = cred.credential as WC3VerifiableCredential;
    return _cred.id;
  }
  const _cred = cred.credential as IndyCredInfo;
  return _cred.referent;
}

export const getCredentiaExchangelId = (cred: AppContextCredential) => {
  if (cred.type === CredentialType.ACAPY_W3C_CREDENTIAL || cred.type === CredentialType.INDY_CREDENTIAL_V2) {
    const _exchange = cred.exchange as V20CredExRecordDetail;
    return _exchange?.cred_ex_record?.cred_ex_id;
  }
  else if (cred.type === CredentialType.WALTID_W3C_CREDENTIAL) {
    return undefined;
  }
  const _exchange = cred.exchange as V10CredentialExchange;
  return _exchange?.credential_exchange_id;
}