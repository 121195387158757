import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ConnRecord, V10CredentialExchange, V10PresentationExchange, V20CredExRecordDetail, V20PresExRecord } from '../../../models/AcaPyModels';
import { WalletActivity, WalletActivityType } from './ActivityView';
import ActivityConnectionLabel from './ActivityConnectionLabel';
import ActivityIcons from './ActivityIcons';
import { BasicMessageWrapper } from '../../../models/CloudWalletTypes';
import BasicMessageDetails from './BasicMessageDetails';
import ActivityTimestamp from './ActivityTimestamp';
import ActivityLabel from './ActivityLabel';
import ConnectionDetails from './ConnectionDetails';
import V10CredExchangeDetails from './V10CredExchangeDetails';
import V20CredExchangeDetails from './V20CredExchangeDetails';
import { getActivityId } from '../../../utils/walletUtils';
import V10PresExchangeDetails from './V10PresExchangeDetails';
import V20PresExchangeDetails from './V20PresExchangeDetails';

const ActivityList: React.FC<{activities: WalletActivity[]}> = ({activities}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getAccordionDetails = (activity: WalletActivity): React.ReactElement => {
    switch (activity.type) {
      case WalletActivityType.BASIC_MESSAGE:
        const msg = activity.data as BasicMessageWrapper;
        return <BasicMessageDetails msg={msg}/>;
      case WalletActivityType.CONNECTION:
        const conn = activity.data as ConnRecord;
        return <ConnectionDetails connRecord={conn}/>;
      case WalletActivityType.V10_CRED_EXCHANGE:
        const v10cred = activity.data as V10CredentialExchange;
        return <V10CredExchangeDetails credEx={v10cred}/>;
      case WalletActivityType.V20_CRED_EXCHANGE:
        const v20cred = activity.data as V20CredExRecordDetail;
        return <V20CredExchangeDetails credEx={v20cred}/>
      case WalletActivityType.V10_PROOF_REQUEST:
        const v10proof = activity.data as V10PresentationExchange;
        return <V10PresExchangeDetails presEx={v10proof} showActions={true}/>
      case WalletActivityType.V20_PROOF_REQUEST:
        const v20proof = activity.data as V20PresExRecord;
        return <V20PresExchangeDetails presEx={v20proof}/>;
    }
  }

  const removeKey = (params: URLSearchParams, keyToRemove: string): URLSearchParams => {
    params.delete(keyToRemove);
    return params;
  }

  const accordionOnChange = (activity: WalletActivity, activityId: string, expanded: boolean) => {
    setSearchParams(old => (expanded ? {...old, key: activityId} : removeKey(old, "key")));
  }
  
  return (
    <Container>
      { activities.map((activity, index) => 
        <Accordion 
          elevation={3} 
          key={`activity-${index}-${getActivityId(activity)}`} 
          expanded={searchParams.get("key") === `${index}-${getActivityId(activity)}`}
          onChange={(e, expanded) => accordionOnChange(activity, `${index}-${getActivityId(activity)}`, expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Grid container sx={{alignItems: "center", marginRight: {sm: tokens.spacing.xs}}}>
              <Grid item xs={3} sm={2} md={"auto"}>
                <ActivityIcons activity={activity}/>
              </Grid>
              <Grid item xs={9} sm={10} md={4}>
                <ActivityLabel activity={activity}/>
              </Grid>
              <Grid item xs={9} sm={true} md={true}>
                <ActivityConnectionLabel activity={activity}/>
              </Grid>
              <Grid item xs={12} sm={"auto"} md={"auto"}>
                <ActivityTimestamp activity={activity}/>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            { getAccordionDetails(activity) }
          </AccordionDetails>
        </Accordion>
      )}
    </Container>
  );
}

export default ActivityList;