import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { WaltidCredential, WaltidCredentialsList, WaltidUserInfo } from '../models/WaltidModels';


const apiRootUrl = process.env.REACT_APP_WALTID_WALLET_API_URL || 'http://localhost:7072/api'
const apiKey = process.env.REACT_APP_CLOUD_WALLET_API_KEY || "";
const companyTokenKey = "x-minisuomi-company-token";
const sessionTokenKey = "x-minisuomi-waltid-session";

const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  const token = sessionStorage.getItem(sessionTokenKey);
  if (token && config.headers) {
    config.headers[sessionTokenKey] = token;
  }
  return config;
}

const responseInterceptor = (response: AxiosResponse<any>) => {
  const token = response.headers[sessionTokenKey];
  if (token) {
    sessionStorage.setItem(sessionTokenKey, token);
  }
  return response;
}

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
  withCredentials: true
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(responseInterceptor);

export async function Login(): Promise<AxiosResponse<WaltidUserInfo>> {
  const token = sessionStorage.getItem(companyTokenKey);
  if (token) {
    const headers = {[companyTokenKey]: token};
    return api.get<WaltidUserInfo>("/wallet/login", {headers});
  }
  return new Promise((resolve, reject) => reject("Not logged in"));
}

export async function ListCredentials(): Promise<AxiosResponse<WaltidCredential[]>> {
  return api.get<WaltidCredential[]>(`/wallet/credentials/list`);
}

export async function DeleteCredential(id: string): Promise<AxiosResponse> {
  return api.delete(`/wallet/credentials/delete/${id}`);
}

export async function LogOut(): Promise<any> {
  sessionStorage.removeItem(sessionTokenKey);
  return new Promise(resolve => resolve("Logged out"));
}
